import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import Modal from "../../components/Modal/Modal";
import Confirm from "../../components/Modals/Confirm/Confirm";
import { setBodegasAndLotes } from "../../store/appSlice";
import { spendSurgeryApi } from "../../services/surgeries";

const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];

const SpendSurgery = ({
  hasFileSpend,
  fileSpend,
  surgeryId,
  usedLotes,
  setSurgery,
  isDisabled,
  onUpload,
  hasLoteInTransit,
  hasLoteGastado,
  hasLoteToDispatch,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [gastoFile, setGastoFile] = useState(null);
  const [localFile, setLocalFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(
    fileSpend ? "Archivo subido" : ""
  );
  const [spendError, setSpendError] = useState(false);
  const [confirmCreateGasto, setConfirmCreateGasto] = useState(false);
  const [noLotesToSpend, setNoLotesToSpend] = useState(false);
  const [hasLotesInTransit, setHasLotesInTransit] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (hasFileSpend) {
      setGastoFile(fileSpend);
    }
  }, [fileSpend, hasFileSpend]);

  const onButtonClick = () => {
    if (hasLoteInTransit || hasLoteToDispatch || hasLoteGastado) {
      setHasLotesInTransit(true);
    } else if (!usedLotes.length) {
      setNoLotesToSpend(true);
    } else {
      // Trigger the file input element.
      fileInputRef.current.click();
    }
  };

  const onDragOver = (event) => {
    // Prevent the default behavior of the browser when a file is dragged over the drop zone.
    event.preventDefault();
    setIsDragging(true);
  };

  const handleUpload = (file) => {
    if (isFileSizeRigth(file.size)) {
      setConfirmCreateGasto(true);
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    // Get the file that was dropped into the drop zone.
    const file = event.dataTransfer.files[0];
    setLocalFile(file);

    if (allowedTypes.includes(file.type)) {
      if (hasLoteInTransit || hasLoteToDispatch) {
        setHasLotesInTransit(true);
      } else if (!usedLotes.length) {
        setNoLotesToSpend(true);
      } else {
        handleUpload(file);
      }
    }
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    setLocalFile(file);
    handleUpload(file);
  };

  const uploadFile = async (file) => {
    setUploadStatus("Subiendo archivo");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("usedLotes", JSON.stringify(usedLotes));
      const updatedSurgery = await spendSurgeryApi(surgeryId, formData);
      setSurgery((prevState) => ({
        ...prevState,
        fileSpend: updatedSurgery.surgery.fileSpend,
      }));
      dispatch(setBodegasAndLotes(updatedSurgery.updatedValues));
      return updatedSurgery.surgery.fileSpend;
    } catch (err) {
      console.log("Ness error", err);
      throw err;
    }
  };

  const isFileSizeRigth = (fileSize) => {
    return fileSize < 5 * 1024 * 1024;
  };

  return (
    <div className="SpendSurgery">
      <div
        className={`drop-zone ${isDragging ? "drop-zone-dragging" : ""}`}
        onDragOver={!hasFileSpend ? onDragOver : undefined}
        onDrop={!hasFileSpend ? onDrop : undefined}
      >
        <p>
          Arrastra tu archivo aquí
          <br />{" "}
          <span
            className={`file-type ${
              !!localFile && !allowedTypes.includes(localFile?.type)
                ? "is-max"
                : ""
            }`}
          >
            (.png, .jpeg, .pdf)
          </span>
        </p>
        <p
          className={`max-file ${
            localFile?.size && !isFileSizeRigth(localFile.size) ? "is-max" : ""
          }`}
        >
          Tamaño máximo 5MB
        </p>
        <input
          type="file"
          className="input-upload"
          accept=".pdf,image/jpeg,image/png"
          ref={fileInputRef}
          onChange={(e) => {
            onFileChange(e);
            e.target.value = null;
          }}
        />
        <Button
          disabled={hasFileSpend || isDisabled}
          label="Buscar archivos"
          click={onButtonClick}
          size="xxsmall"
        />
      </div>
      <div className="uploaded-files">
        <p>{uploadStatus}</p>
        {(uploadStatus === "Subiendo archivo" ||
          uploadStatus === "Borrando archivo") && (
          <div className="loading-wrapper">
            <Loading />
          </div>
        )}
        {gastoFile && (
          <div className="uploaded-files-list">
            <span>
              <img
                src={`/assets/images/${gastoFile?.type.split("/")[1]}.png`}
                alt="asset type icon"
              />
              <p>{gastoFile?.name.toUpperCase()}</p>
            </span>
            <a
              href={gastoFile.url}
              target="_blank"
              className="link"
              rel="noreferrer"
            >
              Ver archivo
            </a>
          </div>
        )}
      </div>
      <Modal isOpen={confirmCreateGasto}>
        <Confirm
          copy="Estás seguro de registrar este gasto?"
          note={{
            copy: "Esto eliminará lotes que hayas elegido en la sección Material",
            color: "#EE8D1C",
          }}
          close={() => setConfirmCreateGasto(false)}
          confirm={async () => {
            if (onUpload) {
              setUploadStatus("Subiendo archivo");
              onUpload(localFile)
                .then((fileUploaded) => {
                  setGastoFile(fileUploaded);
                  setUploadStatus("Archivo subido");
                  setConfirmCreateGasto(false);
                })
                .catch((err) => {
                  setSpendError(err.message);
                  setConfirmCreateGasto(false);
                  setUploadStatus("");
                });
            } else {
              uploadFile(localFile)
                .then((fileUploaded) => {
                  setGastoFile(fileUploaded);
                  setUploadStatus("Archivo subido");
                  setConfirmCreateGasto(false);
                })
                .catch((err) => {
                  setSpendError(err.message);
                  setConfirmCreateGasto(false);
                  setUploadStatus("");
                });
              setConfirmCreateGasto(false);
            }
          }}
        />
      </Modal>
      <Modal isOpen={noLotesToSpend}>
        <Confirm
          copy="No hay lotes para gastar"
          note={{
            copy: "Selecciona lotes antes de hacer un gasto",
            color: "#EE8D1C",
          }}
          close={() => setNoLotesToSpend(false)}
          closeLabel="Cerrar"
        />
      </Modal>
      <Modal isOpen={hasLotesInTransit}>
        <Confirm
          copy="No es posible gastar lote(s) en transito, por despachar o ya gastado"
          close={() => setHasLotesInTransit(false)}
          closeLabel="Cerrar"
        />
      </Modal>
      <Modal isOpen={spendError}>
        <Confirm
          copy="Ha ocurrido un error"
          note={{
            copy: spendError,
            color: "#EE8D1C",
          }}
          close={() => setSpendError(false)}
          closeLabel="Cerrar"
        />
      </Modal>
    </div>
  );
};

export default SpendSurgery;
