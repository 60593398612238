import Bodegas from "../../components/Bodegas";

function MobileWarehouses() {
  return (
    <div className="mobile-container bg-white pt-10 min-h-screen">
      <Bodegas />
    </div>
  );
}

export default MobileWarehouses;
