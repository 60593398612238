import React from "react";
import Container from "../../components/Container/Container";
import Page from "../../layout/Page/Page";
import { isMobile } from "../../utils/core";
import { Link } from "react-router-dom";

const NotFound = () => {
  if (isMobile()) {
    return (
      <div className="only-on-mobile">
        <p>Nothing found</p>
        <Link to="/">Ir a la pagina principal</Link>
      </div>
    );
  }

  return (
    <Page>
      <Container title="Oops!" subtitle="404">
        <p>Nothing found</p>
      </Container>
    </Page>
  );
};

export default NotFound;
