import React, { useRef, useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LotesFinder = () => {
  const [lotesTerm, setLotesTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const lotes = useSelector((state) => state.app.lotes);
  const bodegas = useSelector((state) => state.app.bodegas);
  const ref = useRef(null);
  const navigate = useNavigate();

  const filteredLotes = lotes.filter(
    (l) =>
      l.lote.toLowerCase().includes(lotesTerm.toLowerCase()) ||
      l.product.nombre.toLowerCase().includes(lotesTerm.toLowerCase()) ||
      l.product.codigo.toLowerCase().includes(lotesTerm.toLowerCase()) ||
      l.bodegaName.toLowerCase().includes(lotesTerm.toLowerCase())
  );

  const closeResults = (e) => {
    if (!ref.current?.contains(e.target) && e.target.tagName !== "SPAN") {
      setShowSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeResults);
    return () => {
      document.removeEventListener("click", closeResults);
    };
  }, []);

  return (
    <div ref={ref} className="w-[300px] cursor-pointer">
      {!showSearch ? (
        <span className="flex items-center" onClick={() => setShowSearch(true)}>
          <FaSearch className="mr-2" />
          Buscar lotes
        </span>
      ) : (
        <>
          <input
            className="w-[300px] px-2 py-1 text-black text-sm rounded-md focus-within:border-transparent focus-within:outline outline-blue-700"
            type="text"
            placeholder="Buscar lotes"
            value={lotesTerm}
            autoFocus
            onInput={(e) => setLotesTerm(e.target.value)}
          />
          <div
            className={`absolute w-[300px] max-h-[300px] ${
              !filteredLotes?.length ? "hidden" : ""
            } font-normal mt-1 py-2 text-black bg-white border border-t-cadet-blue-crayola rounded-md bg-red overflow-y-scroll`}
          >
            {filteredLotes?.length > 0 &&
              filteredLotes.map((lote) => (
                <div
                  key={lote._id}
                  className="p-2 hover:bg-slate-50"
                  onClick={() => {
                    navigate(`/bodegas/lotes/${lote.lote}`, {
                      state: {
                        bodega: bodegas.find((b) => b._id === lote.bodegaId),
                        lote,
                      },
                    });
                    setShowSearch(false);
                  }}
                >
                  <p className="text-xs">{lote.lote}</p>
                  <div className="flex justify-between text-[8px]">
                    <p>{lote.product.nombre}</p>
                    <p>{lote.bodegaName}</p>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LotesFinder;
