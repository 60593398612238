import moment from "moment";

const DashboardSurgeryRow = ({
  clinic,
  date,
  patient,
  city,
  clientName,
  spent,
  dispatched,
  invoice,
}) => {
  return (
    <div>
      <div className="flex justify-between text-xs mb-3">
        {/* <div className="hidden w-[23px] h-[23px] lg:flex justify-center items-center self-center bg-raisin-black rounded-full">
          <p className="text-[10px] text-white">EG</p>
        </div> */}
        <div className="w-[40%] lg:w-[27%]">
          <p>{clinic}</p>
          <p className="text-[10px] font-light">{clientName}</p>
        </div>
        <p className="w-[35%] lg:w-[18%]">
          {date ? moment(date).format("DD MMMM YYYY") : "Sin fecha"}
        </p>
        <p className="hidden lg:inline w-[27%]">
          {patient?.length > 30 ? `${patient.substring(0, 30)}...` : patient}
        </p>
        <p className="flex w-[15%] lg:w-[18%] justify-end lg:justify-start">
          {city}
        </p>
        <div className="hidden lg:flex gap-2">
          <div
            className={`w-[8px] h-[8px] ${
              invoice ? "bg-malachite" : "bg-orange-500"
            } rounded-full`}
          ></div>
          <div
            className={`w-[8px] h-[8px] ${
              dispatched ? "bg-malachite" : "bg-orange-500"
            } rounded-full`}
          ></div>
          <div
            className={`w-[8px] h-[8px] ${
              spent ? "bg-malachite" : "bg-orange-500"
            } rounded-full`}
          ></div>
        </div>
      </div>
      <div className="border-[0.5px] border-[#E3E3E3}"></div>
    </div>
  );
};

export default DashboardSurgeryRow;
