import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

const IngresosLotesModal = ({ setIsOpen, entries, removeLote }) => {
  const [searchTerm, setSearchTerm] = useState([]);

  let filteredData = entries.filter((element) =>
    Object.values(element).some((value) => {
      if (typeof value === "object") {
        return JSON.stringify(value).toLowerCase().includes(searchTerm);
      } else if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm);
      }
      return false;
    })
  );

  const LotesRows = ({ ingresoId, lote }) => {
    return (
      <tr>
        <td className="p-1 font-light text-sm">{lote.loteCode}</td>
        <td className="p-1 font-light text-sm">{lote.description}</td>
        <td className="p-1 font-light text-sm">{lote.lote}</td>
        <td className="p-1 font-light text-sm text-center">
          {moment(lote.expiryDate).format("DD/MM/YYYY")}
        </td>
        {removeLote && (
          <td className="">
            <FontAwesomeIcon
              className="cursor-pointer"
              icon="trash"
              color="#8F95B2"
              onClick={() => {
                removeLote(ingresoId, lote.id);
              }}
            />
          </td>
        )}
      </tr>
    );
  };
  return (
    <div className="w-[800px] p-7 min-h-[500px]">
      <div className="flex justify-between items-center">
        <span className="basis-[5%]"></span>
        <p className="basis-[60%] text-center text-[1.5em] font-semibold">
          Listado de lotes
        </p>
        <div
          className="basis-[5%] close-modal-container text-right"
          onClick={() => setIsOpen(false)}
        >
          <FontAwesomeIcon icon="times-circle" />
        </div>
      </div>
      <div className="w-3/4 mx-auto mt-8">
        <div className="flex items-center px-4 py-2 border border-cadet-blue-crayola rounded-lg focus-within:border-transparent focus-within:outline outline-blue-700">
          <FaSearch className="fill-cool-grey mr-2" />
          <input
            className="w-full border-none outline-none placeholder:text-cool-grey"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Buscar lotes"
          />
        </div>
      </div>
      <table className="mt-8 min-w-full">
        <thead>
          <tr>
            <th className="p-2 text-left font-medium">Código</th>
            <th className="p-2 text-left font-medium">Referencia</th>
            <th className="p-2 text-left font-medium">Lote</th>
            <th className="p-2 text-left font-medium">Fecha de vencimiento</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((ingreso) => {
            return ingreso.lotes.map((lote) => (
              <LotesRows key={lote.id} ingresoId={ingreso.id} lote={lote} />
            ));
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IngresosLotesModal;
