const DespachosMenu = () => {
  return (
    <div className="grid grid-cols-5 justify-items-center pb-5">
      <p className="underline cursor-pointer font-semibold">Fecha</p>
      <p className="underline cursor-pointer font-semibold">No. Remisión</p>
      <p className="underline cursor-pointer font-semibold">Cliente</p>
      <div className="flex">
        <p className="underline cursor-pointer font-semibold">Estado</p>
      </div>
      <p className="underline cursor-pointer font-semibold">Gestión</p>
    </div>
  );
};

export default DespachosMenu;
