import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Status from "../../components/Status/Status";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setCreateBodegaModal,
  setCreateClientModal,
} from "../../store/appSlice";
import LotesFinder from "../../components/LotesFinder/LotesFinder";
import "./Topbar.css";

const Topbar = () => {
  const navigate = useNavigate();
  const [totalSurgeriesNoQuote, setTotalSurgeriesNoQuote] = useState(0);
  const [totalSurgeriesNoDispatch, setTotalSurgeriesNoDispatch] = useState(0);
  const [totalSurgeriesNoSpend, setTotalSurgeriesNoSpend] = useState(0);
  const content = useSelector((state) => state.app.content);
  const surgeries = useSelector((state) => state.app.surgeries);
  const bodegas = useSelector((state) => state.app.bodegas);
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const createButton = generateCreateButton();

  function generateCreateButton() {
    let label = "CREATE_SURGERY";
    let url = "/cirugias/crear-cirugia";
    let state = "";
    let modal = null;
    if (path === "/bodegas") {
      label = "CREATE_BODEGA";
      modal = setCreateBodegaModal(true);
    } else if (
      !path.includes("/lotes/") &&
      path.startsWith("/bodegas/") &&
      path.slice("/bodegas/".length).trim() !== ""
    ) {
      label = "CREATE_DISPATCH";
      url = `/despachos/${path.split("/")[2]}`;
      state = location.state;
    } else if (path === "/despachos") {
      label = "CREATE_DISPATCH";
      url = `/despachos/crear-despacho`;
      state = { bodega: bodegas.find((bodega) => bodega.name === "Principal") };
    } else if (path === "/despachos/crear-despacho") {
      label = "CREATE_BODEGA";
      url = `/bodegas`;
      modal = setCreateBodegaModal(true);
    } else if (path === "/ingresos" || path === "/ingresos/crear-ingreso") {
      label = "CREATE_INGRESO";
      url = "/ingresos/crear-ingreso";
    } else if (path === "/egresos" || path.startsWith("/egresos/")) {
      label = "CREATE_EGRESO";
      url = "egresos/crear-egreso";
    } else if (path === "/clientes") {
      label = "CREATE_CLIENT";
      modal = setCreateClientModal(true);
    }
    return {
      label: content.button[label],
      url,
      state,
      modal,
    };
  }

  useEffect(() => {
    if (surgeries) {
      let noQuote = 0;
      let noDispatch = 0;
      let noSpend = 0;

      surgeries.forEach((surgery) => {
        if (!surgery.quoteNumber) {
          noQuote += 1;
        }
        if (!surgery.despachos?.length) {
          noDispatch += 1;
        }
        if (!surgery.fileSpend) {
          noSpend += 1;
        }
      });

      setTotalSurgeriesNoQuote(noQuote);
      setTotalSurgeriesNoDispatch(noDispatch);
      setTotalSurgeriesNoSpend(noSpend);
    }
  }, [surgeries]);

  const quoteStatus = {
    status: `${totalSurgeriesNoQuote > 0 ? "active" : "complete"}`,
    label: `${content.texts.TO_QUOTE}: ${totalSurgeriesNoQuote}`,
  };
  const despatchStatus = {
    status: `${totalSurgeriesNoDispatch > 0 ? "active" : "complete"}`,
    label: `${content.texts.TO_DISPATCH}: ${totalSurgeriesNoDispatch}`,
  };
  const spentStatus = {
    status: `${totalSurgeriesNoSpend > 0 ? "active" : "complete"}`,
    label: `${content.texts.TO_SPEND}: ${totalSurgeriesNoSpend}`,
  };
  const createSurgeryButton = {
    label: createButton.label,
    size: "small",
    disabled:
      location.pathname === "/cirugias/crear-cirugia" ||
      location.pathname === "/ingresos/crear-ingreso" ||
      location.pathname === "/egresos/crear-egreso"
        ? true
        : false,
    click: () =>
      createButton.modal && path === "/despachos/crear-despacho"
        ? navigateAndModal()
        : createButton.modal
        ? dispatch(createButton.modal)
        : navigate(createButton.url, { state: createButton.state }),
  };

  function navigateAndModal() {
    navigate(createButton.url, { state: createButton.state });
    dispatch(createButton.modal);
  }

  return (
    <div className={`Topbar`}>
      <div className="topbar-container">
        <div className="topbar-button">
          <Button {...createSurgeryButton} />
        </div>
        <LotesFinder />
        <div className="topbar-status">
          <Status {...quoteStatus} />
          <Status {...despatchStatus} />
          <Status {...spentStatus} />
        </div>
      </div>
    </div>
  );
};

export default Topbar;
