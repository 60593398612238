import moment from "moment";
import { FaCheckCircle } from "react-icons/fa";

const DespachoRow = ({ item, index, onSelect, selected, onShiftClick }) => {
  const handleClick = (e) => {
    if (e.shiftKey) {
      onShiftClick(index);
    } else {
      onSelect({ ...item, selected: !item.selected }, index);
    }
  };

  return (
    <div
      className={`w-4/5 flex justify-between items-center bg-cultured rounded-lg pl-8 pr-4 py-4 mb-4 ${
        item.inTransit ? "opacity-50" : ""
      }`}
    >
      <div className="cursor-default">
        <h2 className="text-lg font-semibold">
          {item.inTransit ? (
            <span className="text-carrot-orange">{item.lote}</span>
          ) : (
            <span>{item.lote}</span>
          )}
        </h2>
        <p className="text-xs font-light">
          {item.product.nombre} - {item.product.codigo}
        </p>
      </div>
      <div
        className="flex justify-end items-center cursor-pointer select-none"
        onClick={!item.inTransit ? (e) => handleClick(e) : undefined}
      >
        <div className="h-12 w-px bg-green-300 mr-8"></div>
        <p className="text-xs font-light w-16 text-right">
          {moment(item.expiryDate).format("DD/MM/YYYY")}
        </p>
        {selected ? (
          <FaCheckCircle size={13} className="self-start fill-raisin-black" />
        ) : (
          <div className="w-[13px] h-[13px] self-start rounded-full border border-cool-grey select-none"></div>
        )}
      </div>
    </div>
  );
};

export default DespachoRow;
