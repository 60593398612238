import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { checkInvitationToken } from "../services/auth";
import Loading from "../components/Loading/Loading";
import { Navigate } from "react-router";

const InvitedLayout = () => {
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitationToken = queryParams.get("token");

  useEffect(() => {
    if (tokenIsValid) {
      setLoading(false);
    }
  }, [tokenIsValid]);

  useEffect(() => {
    const checkTokenValidity = () => {
      checkInvitationToken(invitationToken)
        .then((res) => setTokenIsValid(res.tokenValid))
        .catch(() => setTokenIsValid(false))
        .finally(() => setLoading(false));
    };
    if (invitationToken) {
      checkTokenValidity();
    } else {
      setLoading(false);
    }
  }, [invitationToken]);

  if (!invitationToken) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="InvitedLayout">
      <Outlet context={[tokenIsValid, invitationToken]} />
    </div>
  );
};

export default InvitedLayout;
