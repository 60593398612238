const DashboardRow = ({ firstLine1, firstLine2, secondLine, thirdLine }) => {
  return (
    <div>
      <div className="flex justify-between text-xs mb-3">
        <div>
          <p>
            {firstLine1 && firstLine2
              ? `${firstLine1} - ${firstLine2}`
              : firstLine1}
          </p>
          <p className="text-[10px] font-light">{secondLine}</p>
        </div>
        <p>{thirdLine}</p>
      </div>
      <div className="border-[0.5px] border-[#E3E3E3}"></div>
    </div>
  );
};

export default DashboardRow;
