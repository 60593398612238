import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";

const MobileProtected = () => {
  const user = useSelector((state) => state.app.user);

  return user?.role === "admin" ? (
    <div className="">
      <Outlet />
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default MobileProtected;
