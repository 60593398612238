import { url, axiosPrivate } from ".";

export const getLotesApi = async () => {
  try {
    const lotes = await axiosPrivate.get(`${url}/lotes`);
    return lotes.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const ingresarLotesApi = async (lotesString) => {
  try {
    const lotes = await axiosPrivate.post(`${url}/lotes`, { lotesString });
    return lotes.data.data;
  } catch (e) {
    console.log(e);
  }
};
