import React from "react";
import { useDispatch } from "react-redux";
import {
  handleModalInputValue,
  handlePageInputValue,
} from "../../store/appSlice";
import "./InputTextarea.css";

const InputTextarea = ({
  id,
  label,
  value,
  className,
  inputsParent,
  handleChange,
}) => {
  const dispatch = useDispatch();

  const handleInputValue = (e) => {
    if (inputsParent === "pageInputs") {
      dispatch(
        handlePageInputValue({
          id,
          value: e.target.value,
        })
      );
    } else {
      dispatch(handleModalInputValue({ value: e.target.value, id }));
    }
  };

  return (
    <div className={`InputTextarea`}>
      <textarea
        className={`${className}`}
        name={label}
        value={value}
        onChange={(e) => (handleChange ? handleChange(e) : handleInputValue(e))}
      >
        {value}
      </textarea>
    </div>
  );
};

export default InputTextarea;
