export const getNavigationItems = (content) => {
  return [
    {
      id: 1,
      name: content.labels.HOME,
      url: "/",
      icon: "home",
    },
    {
      id: 2,
      name: content.labels.SURGERIES,
      url: "/cirugias",
      icon: "notes-medical",
    },
    {
      id: 3,
      name: content.labels.WAREHOUSES,
      url: "/bodegas",
      icon: "warehouse",
    },
    {
      id: 4,
      name: content.labels.CLIENTS,
      url: "/clientes",
      icon: "users",
    },
    {
      id: 5,
      name: content.labels.SHIPMENTS,
      url: "/despachos",
      icon: "truck",
    },
    {
      id: 6,
      name: content.labels.ENTER_LOTES,
      url: "/ingresos",
      icon: "sign-in-alt",
      protected: "admin",
    },
    {
      id: 7,
      name: "Egresos",
      url: "/egresos",
      icon: "external-link-alt",
    },
    {
      id: 8,
      name: "Inventario",
      url: "/inventario",
      icon: "clipboard",
    },
  ];
};
