// eslint-disable-next-line no-unused-vars
import { createSlice, current } from "@reduxjs/toolkit";
import { getPageInputs } from "../content/elements/NewSurgery/pageInputs";
import { getModalInputs } from "../content/elements/NewSurgery/modalInputs";
import ES_Content from "../content/languages/Spanish";
import EN_Content from "../content/languages/English";
import { convertToSuggestions, generateInputDashValue } from "../utils";
import { postNewEntity, setAxiosPrivateHeaders } from "../services";
import {
  addSurgery,
  deleteSurgeryApi,
  enterSurgeryQuotationNumber,
  updateSurgeryApi,
  updateSurgeryStatus,
} from "../services/surgeries";
import { getAllUserData } from "../services/";
import { checkLogin } from "../services/auth";
import {
  getFilterOptions,
  getFilterStatus,
} from "../content/elements/filterOptions";
import moment from "moment";
import { getTabs } from "../pages/Surgeries/Surgeries.elements";

const initialState = {
  user: {
    role: localStorage.getItem("role"),
  },
  ws: null,
  isLogged: JSON.parse(localStorage.getItem("isLogged")),
  token: localStorage.getItem("token") || "",
  language: localStorage.getItem("language") || "Spanish",
  content:
    localStorage.getItem("language") === "English" ? EN_Content : ES_Content,
  showModal: false,
  pageInputs: [],
  modalInputs: [],
  modalHeading: "",
  entityDbId: "",
  suggestions: [],
  isSaveSurgeryEnabled: false,
  isEditSurgeryEnabled: false,
  isSaveEntityEnabled: false,
  isSaveToRefresh: false,
  isLoading: false,
  justEdited: false,
  isCreateBodegaModalOpen: false,
  filterBodegasTerm: "",
  selectedEntityId: 0,
  recentEntity: "",
  allSurgeries: [], // all<Elements_name> used for intact elements - do not modify it, modify 'surgeries' instead
  surgeries: [],
  filteredSurgeries: [],
  clients: [],
  clinics: [],
  patients: [],
  bodegas: [],
  lotes: [],
  specialists: [],
  materials: [],
  despachos: [],
  parsedSurgeries: [],
  filterOptions: getFilterOptions,
  serverResponse: "",
  filterQuery: "",
  filterStatus: getFilterStatus,
  selectedFilters: [],
  isShowsMaterials: false,
  surgeriesTabs: [],
  surgeriesList: [],
  isPairOpen: false,
  totalLotes: 0,
};

// Reducer that handles the global state of the application
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.isLogged = action.payload.isLogged;
      localStorage.setItem("isLogged", JSON.stringify(action.payload.isLogged));
      localStorage.setItem("role", action.payload.data.user?.role);
      localStorage.setItem("email", action.payload.data.user?.email);
      localStorage.setItem(
        "logo",
        action.payload.data.user?.organization?.logo
      );

      state.token = action.payload.token;
      localStorage.setItem("token", action.payload.token);
      setAxiosPrivateHeaders();

      state.serverResponse = "";

      state.isSaveToRefresh = true;
    },
    logout: (state) => {
      state.isLogged = false;
      localStorage.setItem("isLogged", JSON.stringify(false));
      localStorage.setItem("role", "");
      localStorage.setItem("logo", "");

      state.token = "";
      localStorage.setItem("token", "");
    },
    setIsSaveToRefresh: (state, action) => {
      state.isSaveToRefresh = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    toggleShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setCreateBodegaModal: (state, action) => {
      state.isCreateBodegaModalOpen = action.payload;
    },
    setFilterBodegasTerm: (state, action) => {
      state.filterBodegasTerm = action.payload;
    },
    setCreateClientModal: (state, action) => {
      state.isCreateClientModalOpen = action.payload;
    },
    handleLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("language", action.payload);
    },
    loadLanguage: (state, action) => {
      state.content = action.payload;
    },
    setPageInputs: (state, action) => {
      state.pageInputs = action.payload;
    },
    populatePageInputValues: (state) => {
      state.pageInputs.forEach((input) => {
        if (
          input.type === "search" &&
          (input.canCreate || input.canIncrement)
        ) {
          if (input.suggestion1b) {
            input.values = convertToSuggestions(
              state[input.db_identifier],
              input.suggestion1,
              input.suggestion2,
              input.suggestion1b
            );
          } else {
            input.values = convertToSuggestions(
              state[input.db_identifier],
              input.suggestion1,
              input.suggestion2
            );
          }
        }
      });
      state.isSaveSurgeryEnabled = false;
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    searchFilterSurgeries: (state, action) => {
      const isDeleting = action.payload.key === "Backspace";
      filterSearchSurgeries(state, action.payload.target.value, isDeleting);
      // FIXME: This can be improved by applying the filter on an already filtered list (instead of applying everything again [this is way more costly])
      // * Apply filters on 'filteredSurgeries'
      // * Remove filters: go back to 'allSurgeries' and apply (run) all other filters again?
      // Option 2: create an object with all filters and add/remove from it and then apply all filters to surgeries
      if (state.selectedFilters.length > 0) {
        applyFilterSurgeries(state);
      }
      if (state.isShowsMaterials) {
        state.surgeriesList = state.surgeriesList.filter(
          (surgery) => surgery.materials.length > 0
        );
      }
    },
    filterSurgeries: (state, action) => {
      const { filter, status, isChecked } = action.payload;
      state.surgeriesList = loadTabSurgeriesList(
        state,
        state.surgeriesTabs.find((tab) => tab.active).name
      );

      if (isChecked) {
        // when clicking on same section checkbox
        if (state.selectedFilters.find((element) => element.name === filter)) {
          state.selectedFilters = state.selectedFilters.filter(
            (element) => element.name !== filter
          );
        }
        state.selectedFilters.push({
          name: filter,
          value: status === "complete" ? true : false,
        });
      } else {
        state.selectedFilters = state.selectedFilters.filter(
          (element) => element.name !== filter
        );
      }

      applyFilterSurgeries(state);

      if (state.filterQuery) {
        filterSearchSurgeries(state, state.filterQuery);
      }
      if (state.isShowsMaterials) {
        state.surgeriesList = state.surgeries.filter(
          (surgery) => surgery.materials.length > 0
        );
      }
    },
    updateSurgery: (state, action) => {
      let parsedSurgery = parseSurgeries([action.payload])[0];
      state.surgeries = state.surgeries.map((surgery) =>
        surgery._id === parsedSurgery._id ? parsedSurgery : surgery
      );

      state.surgeriesList = loadTabSurgeriesList(
        state,
        state.surgeriesTabs.find((tab) => tab.active).name
      );

      if (state.selectedFilters.length > 0) {
        applyFilterSurgeries(state);
      }
    },
    updateDespachoSurgery: (state, action) => {
      const surgery = state.surgeries.find(
        (surgery) => surgery._id === action.payload.surgeryId
      );
      surgery.despachos = action.payload.despachos;
      state.surgeries = state.surgeries.map((s) =>
        s._id === surgery._id ? surgery : s
      );
    },
    clearSurgeriesFilters: (state) => {
      state.surgeries = state.allSurgeries;
      state.selectedFilters = [];
      state.filterStatus = getFilterStatus;
      state.filterQuery = "";
      state.filteredSurgeries = [];
      state.isShowsMaterials = false;
      state.surgeriesList = loadTabSurgeriesList(
        state,
        state.surgeriesTabs.find((tab) => tab.active).name
      );
    },
    clearFilterQuery: (state) => {
      state.filterQuery = "";
      state.surgeriesList = loadTabSurgeriesList(
        state,
        state.surgeriesTabs.find((tab) => tab.active).name
      );
      if (state.selectedFilters.length > 0) {
        applyFilterSurgeries(state);
      }
    },
    populateSurgeries: (state, action) => {
      state.allSurgeries = parseSurgeries(action.payload);
      state.allSurgeries = sortSurgeries(state.allSurgeries);
      state.surgeries = state.allSurgeries;
      // state.surgeriesList = loadTabSurgeriesList(state, "soon");
      state.isLoading = false;
    },
    handleMaterialsFilter: (state) => {
      state.isShowsMaterials = !state.isShowsMaterials;
      if (state.isShowsMaterials) {
        state.surgeriesList = state.surgeriesList.filter(
          (surgery) => surgery.materials.length > 0
        );
      } else {
        state.surgeriesList =
          state.filteredSurgeries.length > 0
            ? state.filteredSurgeries
            : loadTabSurgeriesList(
                state,
                state.surgeriesTabs.find((tab) => tab.active).name
              );
      }
    },
    setSurgeries: (state, action) => {
      state.surgeries = action.payload;
    },
    setSurgeriesList: (state, action) => {
      state.surgeriesList = action.payload;
    },
    setClients: (state, action) => {
      state.clients = [...action.payload].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    },
    addClient: (state, action) => {
      const newClient = action.payload;
      state.clients.push(newClient);
    },
    setClinics: (state, action) => {
      state.clinics = action.payload;
    },
    setPatients: (state, action) => {
      state.patients = action.payload;
    },
    setBodegasAndLotes: (state, action) => {
      state.bodegas = action.payload;
      // get all lotes from all bodegas and place them in one single array
      state.lotes = action.payload.flatMap((bodega) =>
        bodega.stock.map((item) => ({
          ...item,
          bodegaId: bodega._id,
          bodegaName: bodega.name,
          clientId: bodega.client._id,
          clientName: bodega.client.nombre,
        }))
      );
    },
    addBodega: (state, action) => {
      state.bodegas.push(action.payload);
    },
    setLotesDelivered: (state, action) => {
      // Set lotes to in transit false (they have been delivered now)
      for (let bodega of state.bodegas) {
        if (bodega._id === action.payload.bodegaDestination._id) {
          let lotes = action.payload.lotes.map((l) => ({
            ...l,
            inTransit: false,
          }));
          bodega.stock = bodega.stock.map((lote) => {
            const matchingItem = lotes.find((l) => l._id === lote._id);
            return matchingItem ? matchingItem : lote;
          });
          break;
        }
      }
      state.lotes = state.bodegas.flatMap((bodega) =>
        bodega.stock.map((item) => ({
          ...item,
          bodegaId: bodega._id,
          bodegaName: bodega.name,
          clientId: bodega.client._id,
          clientName: bodega.client.nombre,
        }))
      );
    },
    removeSpentLotes: (state, action) => {
      const updatedLotes = state.lotes.filter(
        (lote) => !action.payload.includes(lote._id)
      );
      state.lotes = updatedLotes;
      state.bodegas.forEach((bodega) => {
        bodega.stock = bodega.stock.filter(
          (lote) => !action.payload.includes(lote._id)
        );
      });
    },
    setSpecialists: (state, action) => {
      state.specialists = action.payload;
    },
    setMaterials: (state, action) => {
      state.materials = action.payload;
    },
    addMaterial: (state, action) => {
      const newMaterial = action.payload;
      state.materials.push(newMaterial);
    },
    setDespachos: (state, action) => {
      state.despachos = action.payload;
    },
    addDespacho: (state, action) => {
      state.despachos.push(action.payload);
    },
    enterNewSurgery: (state, action) => {
      state.surgeries.push(parseSurgeries([action.payload])[0]);

      activateTab(state, action.payload);
      clearNewSurgeryInputs(state);
    },
    removeSurgery: (state, action) => {
      const surgery = state.surgeries.find(
        (surgery) => surgery._id === action.payload.surgeryId
      );
      state.allSurgeries = parseSurgeries(action.payload.updatedSurgeries);
      state.allSurgeries = sortSurgeries(state.allSurgeries);
      state.surgeries = state.allSurgeries;
      state.isLoading = false;

      activateTab(state, surgery);
    },
    setJustEdited: (state, action) => {
      state.justEdited = action.payload;
    },
    registerNewEntity: (state, action) => {
      state[action.payload.endpoint].push(action.payload.entity);
      state.showModal = false;
    },
    openCreateModal: (state, action) => {
      state.showModal = true;
      state.selectedEntityId = action.payload.id;
      state.modalHeading = action.payload.label;
      state.entityDbId = action.payload.db_identifier;
      state.modalInputs = getModalInputs(state.content, action.payload.name);
      // assign search term as a value to the first input
      state.modalInputs[0].value = action.payload.value;

      state.modalInputs.forEach((input) => {
        if (input.type === "search") {
          input.values = convertToSuggestions(
            state[input.db_identifier],
            input.suggestion1,
            input.suggestion2
          );
        }
      });
    },
    handleModalInputValue: (state, action) => {
      const input = state.modalInputs.find(
        (input) => input.id === action.payload.id
      );
      input.value = action.payload.value;

      areMandatoryFieldsCompleted(state, "modalInputs");
    },
    clearPageInputFocus: (state) => {
      state.pageInputs.forEach((input) => {
        input.isFocused = false;
      });
    },
    handlePageInputValue: (state, action) => {
      let input = state.pageInputs.find(
        (input) => input.id === action.payload.id
      );
      input.value = action.payload.value;
      input.isSelected = true;
    },
    // TODO: SEARCH SUGGESTIONS:
    // 1. pull all data and then filter suggestions - only one initial request (might be big)
    // 2. pull only the matching suggestions - so making requests on every keystroke
    handlePageSearchInputValue: (state, action) => {
      // update the value of the input
      const { id, value, values, inputs } = action.payload;
      let input = state[inputs].find((input) => input.id === id);
      input.value = value;
      input.isSelected = false;

      const parsedValues = values.map((val) => {
        return {
          id: val.id,
          value: val.name + " - " + val.secondary_name,
        };
      });

      let matchValues = parsedValues.filter((element) =>
        element.value.toLowerCase().includes(input.value.toLowerCase())
      );
      state.suggestions = matchValues.map((element) => {
        return {
          id: element.id,
          value: element.value,
        };
      });

      areMandatoryFieldsCompleted(state, "pageInputs");
    },
    selectSearchSuggestion: (state, action) => {
      const { id, item, inputs, endpoint } = action.payload;
      let input = state[inputs].find((input) => input.id === id);
      input.value = item.value;
      input.entity_id = item.id;
      input.isSelected = endpoint !== "materials" ?? true;

      areMandatoryFieldsCompleted(state, "pageInputs");
    },
    updateMaterialQuantity: (state, action) => {
      // find the input (requested material, clinic, etc)
      let input = state.pageInputs.find(
        (input) => input.id === action.payload.id
      );

      // find the actual material e.g. 5.00 CC
      let currentValue = input.selectedValues.find(
        (material) => material.value === action.payload.item.value
      );

      if (action.payload.action === "increment") {
        if (currentValue) {
          // increment the quantity
          currentValue.quantity += 1;
        } else {
          // push the item (the material e.g. 5.00 CC)
          input.selectedValues.push({
            producto: action.payload.item.id,
            value: action.payload.item.value,
            quantity: 1,
          });
        }
        // else, decrement
      } else {
        if (currentValue) {
          currentValue.quantity -= 1;
        }
        if (currentValue.quantity < 1) {
          input.selectedValues = input.selectedValues.filter(
            (material) => material.producto !== action.payload.item.producto
          );
        }
      }
    },
    deleteSurgeryCardValue: (state, action) => {
      // TODO: Create utily function to get the item with id instead of rewritting the same code
      // update: we could use a function inside the slice file
      let input = state.pageInputs.find(
        (input) => input.id === action.payload.id
      );
      input.value = "";
      input.isSelected = false;

      areMandatoryFieldsCompleted(state, "pageInputs");
    },
    focusPageInput: (state, action) => {
      const { id, inputs } = action.payload;
      // unfocus all inputs
      state[inputs].forEach((input) => {
        input.isFocused = false;
      });

      // focus the input
      const input = state[inputs].find((input) => input.id === id);

      if (input) {
        input.isFocused = true;

        // parse the values for the suggestions
        const values = input.values.map((val) => {
          return {
            id: val.id,
            value: val.name + " - " + val.secondary_name,
          };
        });

        // filter search suggestions - this is necessary when changing input to refresh the suggestions
        let matchValues = values.filter((element) =>
          element.value.toLowerCase().includes(input.value.toLowerCase())
        );
        state.suggestions = matchValues.map((element) => {
          return {
            id: element.id,
            value: element.value,
          };
        });
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setServerResponse: (state, action) => {
      state.serverResponse = action.payload;
    },
    setSurgeriesTabs: (state, action) => {
      state.surgeriesTabs = getTabs(action.payload);
    },
    handleSurgeriesTabs: (state, action) => {
      state.surgeriesTabs.forEach((tab) => {
        if (tab.id === action.payload.id) {
          tab.active = true;
        } else {
          tab.active = false;
        }
      });
      state.surgeriesList = loadTabSurgeriesList(state, action.payload.name);

      // apply search bar filter
      filterSearchSurgeries(state, state.filterQuery, false);

      // apply checkbox filters
      if (state.selectedFilters.length > 0) {
        applyFilterSurgeries(state);
      }

      // apply show/hide materials
      if (state.isShowsMaterials) {
        state.surgeriesList = state.surgeriesList.filter(
          (surgery) => surgery.materials.length > 0
        );
      }
    },
    // TODO: refactor this reducer
    // - it has too many specific checks (as opposed to generic checks)
    populateSurgeryFieldsEdit: (state, action) => {
      const surgery = action.payload;
      const onlyWithValue = state.pageInputs.map((input) => {
        if (input.selectedValues) {
          input.selectedValues = surgery[input.identifier];
        }
        return {
          ...input,
          value: surgery[input.identifier],
        };
      });
      const generateValues = onlyWithValue
        .filter((e) => {
          if (Array.isArray(e.value)) {
            return e.value.length > 0;
          }
          return e.value;
        })
        .map((e) => {
          if (e.selectedValues && e.value.length > 0) {
            return e.value.map((material) => ({
              producto: material.producto._id,
              value: `${material.producto.nombre} - ${material.producto.codigo}`,
              quantity: material.quantity,
            }));
          } else {
            if (e.suggestion1b) {
              return generateInputDashValue(
                e.value,
                e.suggestion1,
                e.suggestion2,
                e.suggestion1b
              );
            } else if (e.suggestion2) {
              return generateInputDashValue(
                e.value,
                e.suggestion1,
                e.suggestion2
              );
            } else if (e.type === "textarea") {
              return {
                type: "textarea",
                value: e.value,
              };
            }
            return e.value;
          }
        });
      const parseValues = generateValues.map((val) => {
        if (val?.id) {
          return {
            id: val.id,
            value: val.name + " - " + val.secondary_name,
          };
        }
        return val;
      });
      state.pageInputs = state.pageInputs.map((input) => {
        const value = parseValues.find(
          (e) => e.id === surgery[input.identifier]?._id
        )?.value;
        if (value) {
          return {
            ...input,
            value: value,
            isSelected: true,
            entity_id: surgery[input.identifier]?._id,
          };
        }
        const values = parseValues.find(
          (e) => Array.isArray(e) && input.selectedValues
        );
        if (values) {
          return {
            ...input,
            value: "",
            selectedValues: values,
            entity_id: surgery[input.identifier]?._id,
          };
        }
        const isDate = parseValues.find((e) => typeof e === "string");
        if (isDate && input.type === "date") {
          return {
            ...input,
            value: moment(isDate).format("YYYY-MM-DDTkk:mm"),
            isSelected: true,
            entity_id: surgery[input.identifier]?._id,
          };
        }
        const textArea = parseValues.find((e) => e.type === "textarea");
        if (input.type === "textarea" && textArea?.value) {
          return {
            ...input,
            value: textArea.value,
            isSelected: true,
            entity_id: surgery[input.identifier]?._id,
          };
        }
        return {
          ...input,
          value: "",
          isSelected: false,
        };
      });
      areMandatoryFieldsCompleted(state, "pageInputs");
    },
    openPair: (state, action) => {
      state.isPairOpen = action.payload;
    },
    setTotalLotes: (state, action) => {
      state.totalLotes = action.payload;
    },
  },
});

export const getListTabName = (surgery) => {
  if (!surgery.fileSpend) {
    const today = moment().format("YYYY-MM-DD");
    if (surgery.fecha_cx > today) {
      return "soon";
    } else {
      return "nogasto";
    }
  }
  if (surgery.fileSpend) {
    return "closed";
  }
  return "nogasto";
};

export const loadTabSurgeriesList = (state, tabName) => {
  // load surgeries list tab
  const today = moment().format("YYYY-MM-DD");
  const sortedSurgeries = state.surgeries.sort((a, b) =>
    moment(a.fecha_cx).diff(moment(b.fecha_cx))
  );
  switch (tabName) {
    case "nogasto":
      return sortedSurgeries.filter(
        (surgery) =>
          !surgery.fileSpend && (surgery.fecha_cx <= today || !surgery.fecha_cx)
      );

    case "closed":
      return sortedSurgeries.filter((surgery) => surgery.fileSpend);
    default:
      return sortedSurgeries.filter(
        (surgery) => surgery.fecha_cx > today && !surgery.fileSpend
      );
  }
};

export const filterSearchSurgeries = (
  state,
  searchParam,
  isDeleting = false
) => {
  let lista =
    isDeleting || state.surgeriesList.length < 1
      ? loadTabSurgeriesList(
          state,
          state.surgeriesTabs.find((tab) => tab.active).name
        )
      : state.surgeriesList;
  state.surgeriesList = lista.filter((surgery) => {
    const query = searchParam.toLowerCase();
    return (
      surgery.patient.toLowerCase().indexOf(query) >= 0 ||
      surgery.clinic?.toLowerCase().indexOf(query) >= 0 ||
      surgery.city?.toLowerCase().indexOf(query) >= 0 ||
      surgery.quoteNumber?.toString().includes(query) ||
      surgery.material_solicitado.find((material) =>
        material.lotes.find(
          (lote) => lote.lote.toLowerCase().indexOf(query) >= 0
        )
      )
    );
  });
  state.filterQuery = searchParam;
  state.filteredSurgeries = state.surgeriesList;
};

export const applyFilterSurgeries = (state) => {
  state.selectedFilters.forEach((element) => {
    state.surgeriesList = state.surgeriesList.filter((surgery) => {
      if (element.name === "quoteNumber") {
        return element.value
          ? surgery.quoteNumber > 0
          : surgery.quoteNumber === 0 || surgery.quoteNumber === null;
      } else if (element.name === "isDispatched") {
        return element.value
          ? !!surgery.despachos?.length
          : !surgery.despachos?.length;
      }
      // element.name === "isSpent"
      return element.value ? surgery.fileSpend : !surgery.fileSpend;
    });
  });
  state.filteredSurgeries = state.surgeriesList;
};

export const sortSurgeries = (surgeries) => {
  const today = moment().format("YYYY-MM-DD");

  const surgeriesWithoutDate = surgeries.filter((surgery) => !surgery.fecha_cx);
  const surgeriesWithPastDate = surgeries.filter(
    (surgery) => surgery.fecha_cx < today
  );
  const surgeriesWithFutureDate = surgeries.filter(
    (surgery) => surgery.fecha_cx > today
  );
  return [
    // "Próximas cirugías",
    ...surgeriesWithFutureDate.sort((a, b) => a.fecha_cx - b.fecha_cx),
    // "Cirugías sin fecha",
    ...surgeriesWithoutDate,
    // "Cirugías pasadas",
    ...surgeriesWithPastDate,
  ];
};

export const activateTab = (state, surgery) => {
  // activate tab of recently added surgery
  const tabName = getListTabName(surgery);
  state.surgeriesList = loadTabSurgeriesList(state, tabName);
  state.surgeriesList = state.surgeriesList.sort((a, b) => {
    return moment(a.date).diff(moment(b.date));
  });
  state.surgeriesTabs.forEach((tab) => {
    if (tab.name === tabName) {
      tab.active = true;
    } else {
      tab.active = false;
    }
  });
};

// FIXME: parse once only (maybe on the server-side?)
/** Parse surgeries for list */
export const parseSurgeries = (values) =>
  values.map((value) => ({
    ...value,
    date: value.fecha_cx,
    fecha_cx: value.fecha_cx,
    patient: value.paciente.nombres + " " + value.paciente.apellidos,
    clinic: value.clinica?.nombre,
    city: value.clinica?.ciudad,
    materials: value.material_solicitado.map((material) => ({
      id: material.producto._id,
      name: material.producto.nombre,
      quantity: material.quantity,
      code: material.producto.codigo,
    })),
  }));

export const clearNewSurgeryInputs = (state) => {
  state.pageInputs.forEach((input) => {
    input.value = "";
    input.isSelected = false;
    // input.selectedValues = []
  });
  state.isSaveSurgeryEnabled = false;
};

export const filterOptionChecking = (option, main, secondary) => {
  // if opposive is checked
  if (option[secondary].isChecked) {
    option[main].isChecked = true;
    option[secondary].isChecked = false;
    // if same is checked
  } else if (option[main].isChecked) {
    option[main].isChecked = false;
    // if neither is checked
  } else {
    option[main].isChecked = true;
  }
};

export const areMandatoryFieldsCompleted = (state, inputs) => {
  // check if new surgery can be saved
  let mandatoryInputs = state[inputs].filter((input) => input.isMandatory);
  if (inputs === "pageInputs") {
    // check if all (every) mandatory inputs are filled
    state.isSaveSurgeryEnabled = mandatoryInputs.every(
      (input) => input.value !== "" && input.isSelected
    );
  } else if (inputs === "modalInputs") {
    // check if all (every) mandatory inputs are filled
    state.isSaveEntityEnabled = mandatoryInputs.every(
      (input) => input.value !== ""
    );
  }
};

export const {
  setLogin,
  setIsSaveToRefresh,
  connectWS,
  logout,
  setUser,
  toggleShowModal,
  setCreateBodegaModal,
  setCreateClientModal,
  setFilterBodegasTerm,
  registerNewEntity,
  handleLanguage,
  loadLanguage,
  enterNewSurgery,
  searchFilterSurgeries,
  setSurgeries,
  setSurgeriesList,
  populateSurgeries,
  setClients,
  addClient,
  setClinics,
  setPatients,
  setBodegasAndLotes,
  setSpecialists,
  setMaterials,
  setDespachos,
  restartSurgeries,
  setPageInputs,
  focusPageInput,
  handlePageSearchInputValue,
  handlePageInputValue,
  updateSurgery,
  updateDespachoSurgery,
  handleModalInputValue,
  openCreateModal,
  clearPageInputFocus,
  selectSearchSuggestion,
  clearSurgeriesFilters,
  deleteSurgeryCardValue,
  filterSurgeries,
  populatePageInputValues,
  populateModalInputValues,
  updateMaterialQuantity,
  populateSurgeryFieldsEdit,
  setServerResponse,
  setJustEdited,
  setFilterStatus,
  handleMaterialsFilter,
  setSurgeriesTabs,
  handleSurgeriesTabs,
  setIsLoading,
  clearFilterQuery,
  removeSurgery,
  addBodega,
  addDespacho,
  setLotesDelivered,
  removeSpentLotes,
  openPair,
  setTotalLotes,
  addMaterial,
} = appSlice.actions;

export const selectLanguage = (state) => state.app.language;

export const changeLanguage = (lang) => (dispatch) => {
  dispatch(handleLanguage(lang));
  import(
    `../content/languages/${lang}` /* webpackChunkName: "language" */
  ).then((module) => {
    console.log(module);
    let loadLang = module.default;
    dispatch(loadLanguage(loadLang));
  });
};

// This is triggered by PrivateLayout on app refresh
export const loadInitialData = () => async (dispatch) => {
  // if there is a token, load the user data
  dispatch(setIsLoading(true));
  if (localStorage.getItem("token") !== "") {
    const getAllData = await getAllUserData();
    dispatch(setInitialData(getAllData));
  }
};

// This is triggered by a successful login
export const setInitialData = (data) => (dispatch) => {
  dispatch(populateSurgeries(data.surgeries));
  dispatch(setUser(data.user));
  dispatch(setClients(data.clients));
  dispatch(setClinics(data.clinics));
  dispatch(setPatients(data.patients));
  dispatch(setBodegasAndLotes(data.bodegas));
  dispatch(setSpecialists(data.specialists));
  dispatch(setMaterials(data.materials));
  dispatch(setDespachos(data.despachos));
  dispatch(setTotalLotes(data.totalLotes));
  localStorage.setItem("db", data.env);
};

export const addNewSurgery = (inputs) => async (dispatch) => {
  try {
    let surgery = prepareData(inputs);
    surgery.fecha_solicitud = new Date();
    const newSurgery = await addSurgery(surgery);
    dispatch(enterNewSurgery(newSurgery));
    return newSurgery;
  } catch (e) {
    console.log(e);
  }
};

export const editSurgery =
  (inputs, surgeryId, material) => async (dispatch) => {
    try {
      let surgery = prepareData(inputs);
      if (surgery.material_solicitado) {
        surgery.material_solicitado = surgery.material_solicitado.map((ms) => ({
          ...ms,
          lotes:
            material.find((m) => m.producto._id === ms.producto)?.lotes || [],
        }));
      }
      const updatedSurgery = await updateSurgeryApi(surgery, surgeryId);
      dispatch(updateSurgery(updatedSurgery));
      return updatedSurgery;
    } catch (e) {
      console.log(e);
    }
  };

export const deleteSurgery = (surgeryId) => async (dispatch) => {
  try {
    const updatedSurgeries = await deleteSurgeryApi(surgeryId);
    dispatch(removeSurgery({ surgeryId, updatedSurgeries }));
  } catch (e) {
    console.log(e);
  }
};

export const prepareData = (inputs) => {
  // get all page inputs with a value or with selected values
  const inputsToSave = inputs.filter(
    (pageInput) =>
      pageInput.value !== "" ||
      (pageInput.canIncrement && pageInput.selectedValues.length > 0)
  );

  let surgery = inputsToSave.map((input) => {
    if (input.canIncrement) {
      return {
        [input.identifier]: [...input.selectedValues],
      };
    }
    if (input.type === "date") {
      return {
        [input.identifier]: new Date(input.value),
      };
    } else if (input.type === "textarea") {
      return {
        [input.identifier]: input.value,
      };
    } else {
      return {
        [input.identifier]: input.entity_id,
      };
    }
  });
  surgery = Object.assign({}, ...surgery);
  return surgery;
};

export const addNewEntity =
  (inputs, endpoint, entityId) => async (dispatch, getState) => {
    // get all page inputs with a value
    const inputsToSave = inputs.filter((modalInput) => modalInput.value !== "");

    let newEntity = inputsToSave.map((input) => {
      if (input.entity_id) {
        return {
          [input.identifier]: input.entity_id,
        };
      }
      return { [input.identifier]: input.value };
    });
    newEntity = Object.assign({}, ...newEntity);
    newEntity.organization = getState().app.user.organization._id;

    try {
      const entity = await postNewEntity(newEntity, endpoint);
      dispatch(registerNewEntity({ entity, endpoint }));
      dispatch(populatePageInputValues());

      // auto-populate page input with value of newly created entity
      const values = getState().app.pageInputs.find(
        (input) => input.db_identifier === endpoint
      ).values;
      const field = values.find((val) => val.id === entity._id);
      const item = {
        id: field.id,
        value: field.name + " - " + field.secondary_name,
      };
      dispatch(
        selectSearchSuggestion({
          item,
          id: entityId,
          endpoint,
          inputs: "pageInputs",
        })
      );
      if (endpoint === "materials") {
        dispatch(
          updateMaterialQuantity({
            id: entityId,
            item,
            action: "increment",
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

export const updateStatus = (surgery, status, value) => async (dispatch) => {
  try {
    const updatedSurgery = await updateSurgeryStatus(surgery, status, value);
    dispatch(updateSurgery(updatedSurgery));
  } catch (e) {
    console.log(e);
  }
};

export const enterQuotationNumber =
  (surgeryId, quoteNumber) => async (dispatch) => {
    try {
      const updatedSurgery = await enterSurgeryQuotationNumber(
        surgeryId,
        quoteNumber
      );
      dispatch(updateSurgery(updatedSurgery));
    } catch (e) {
      console.log(e);
    }
  };

export const login = (email, password, content) => async (dispatch) => {
  try {
    const responseLogin = await checkLogin(email, password);
    dispatch(setLogin(responseLogin));
    dispatch(setInitialData(responseLogin.data));
    dispatch(setPageInputs(getPageInputs(content)));
    dispatch(populatePageInputValues());
  } catch (e) {
    dispatch(setServerResponse(e.response?.data?.message));
  }
};

export default appSlice.reducer;
