import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import Button from "../Button/Button";
import "./Container.css";

const Container = ({
  children,
  title,
  close,
  onAccept,
  acceptLabel,
  withFooter,
  fitHeight,
  isPrimaryBtnDisabled,
  isAcceptLoading,
  columns,
}) => {
  const content = useSelector((state) => state.app.content);
  const modalRef = useRef(null);

  useEffect(() => {
    const closeModalByClick = (e) => {
      if (!modalRef?.current?.contains(e.target)) {
        close();
      }
    };
    const closeModalByKey = (e) => {
      if (e.key === "Escape") {
        close();
      }
    };
    window.addEventListener("keydown", closeModalByKey);
    window.addEventListener("click", closeModalByClick);
    return () => {
      window.removeEventListener("keydown", closeModalByKey);
      window.removeEventListener("click", closeModalByClick);
    };
  }, [close]);

  return (
    <div
      ref={modalRef}
      className={`ModalContainer ${withFooter ? "with-footer" : ""} ${
        fitHeight ? "fit-height" : ""
      } columns-${columns}`}
    >
      <div className="modal-container-header">
        <h2 className="modal-container-heading">{title}</h2>
        <div className="close-modal-container" onClick={() => close()}>
          <FontAwesomeIcon icon="times-circle" />
        </div>
      </div>
      <div className="modal-container-content">{children}</div>
      {withFooter && (
        <div className={`form-footer ${columns ? "footer-right" : ""}`}>
          <Button
            label={content.labels.CANCEL}
            variant="cancel"
            click={() => close()}
          />
          <Button
            label={acceptLabel ? acceptLabel : content.labels.CREATE}
            disabled={!isPrimaryBtnDisabled}
            isLoading={isAcceptLoading}
            click={() => onAccept()}
          />
        </div>
      )}
    </div>
  );
};

export default Container;
