import DashboardCard from "../../components/DashboardCard/DashboardCard";
import QuantityCircle from "../../components/QuantityCircle/QuantityCircle";
import DashboardDispatchRow from "../../components/DashboardRow/DashboardDispatchRow";
import DashboardRow from "../../components/DashboardRow/DashboardRow";
import DashboardSurgeryRow from "../../components/DashboardRow/DashboardSurgeryRow";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function MobileDashboard() {
  const allSurgeries = useSelector((state) => state.app.surgeries);
  let bodegas = useSelector((state) => state.app.bodegas);
  bodegas = [...bodegas].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  const clients = useSelector((state) => state.app.clients);
  const lotes = useSelector((state) => state.app.lotes);
  const despachos = useSelector((state) => state.app.despachos);
  const totalLotes = useSelector((state) => state.app.totalLotes);
  const [surgeries, setSurgeries] = useState([]);

  useEffect(() => {
    let filtered = allSurgeries.filter((s) => s.fileSpend);
    filtered.sort((a, b) => new Date(b.fecha_cx) - new Date(a.fecha_cx));
    setSurgeries(filtered);
  }, [allSurgeries]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 lg:grid-rows-2 gap-4 bg-white p-4 lg:p-12">
      <div className="order-3 lg:col-span-2">
        <DashboardCard title="Cirugías" quantity={surgeries.length}>
          <div className="w-11/12 flex flex-col gap-y-2">
            {surgeries
              .slice(0, 5)
              .map(
                ({
                  _id,
                  city,
                  clinic,
                  patient,
                  fecha_cx,
                  cliente,
                  fileSpend,
                  despachos,
                  quoteNumber,
                }) => (
                  <DashboardSurgeryRow
                    key={_id}
                    city={city}
                    clinic={clinic}
                    patient={patient}
                    date={fecha_cx}
                    clientName={cliente.nombre}
                    spent={!!fileSpend}
                    dispatched={!!despachos.length}
                    invoice={quoteNumber > 0}
                  />
                )
              )}
          </div>
        </DashboardCard>
      </div>
      <div className="order-4 lg:col-start-1 lg:row-start-2">
        <DashboardCard title="Bodegas" quantity={bodegas.length}>
          <div className="w-11/12 flex flex-col gap-y-2">
            {bodegas.slice(0, 5).map(({ city, name, stock, client }) => (
              <DashboardRow
                firstLine1={name}
                firstLine2={city}
                secondLine={client.nombre}
                thirdLine={`${stock.length} ${
                  stock.length === 1 ? "lote" : "lotes"
                }`}
              />
            ))}
          </div>
        </DashboardCard>
      </div>
      <div className="order-5 lg:col-start-2 lg:row-start-2">
        <DashboardCard title="Clientes" quantity={clients.length}>
          <div className="w-11/12 flex flex-col gap-y-2">
            {clients.slice(0, 5).map(({ nombre, tipoId, numeroId, ciudad }) => (
              <DashboardRow
                firstLine1={nombre}
                secondLine={`${tipoId.toUpperCase()}. ${numeroId}`}
                thirdLine={ciudad}
              />
            ))}
          </div>
        </DashboardCard>
      </div>
      <div className="order-1 lg:col-start-3 lg:row-start-1">
        <DashboardCard title="Lotes">
          <QuantityCircle quantity={lotes.length} label="Disponibles" />
          <div className="lg:w-full flex flex-col lg:flex-row gap-y-8 justify-around">
            <div className="text-center">
              <h3 className="font-medium text-[40px] leading-none">
                {totalLotes}
              </h3>
              <p className="text-sm">Usados</p>
            </div>
            <div className="text-center">
              <h3 className="font-medium text-[40px] leading-none">
                {lotes.filter((l) => l.inTransit).length}
              </h3>
              <p className="text-sm">En transito</p>
            </div>
          </div>
        </DashboardCard>
      </div>
      <div className="order-2 lg:col-start-3 lg:row-start-2">
        <DashboardCard title="Despachos">
          <div className="w-full flex justify-around items-center mb-4">
            <QuantityCircle quantity={despachos.length} label="Realizados" />
            <div className="text-center">
              <h3 className="font-medium text-[40px] leading-none">
                {despachos.filter((d) => !d.remisionFirmadaFile).length}
              </h3>
              <p className="text-sm">En transito</p>
            </div>
          </div>
          <div className="w-11/12 flex flex-col gap-y-2 mt-5">
            {despachos
              .slice(0, 4)
              .map(
                ({
                  remisionNumber,
                  remisionFirmadaFile,
                  bodegaDestination,
                }) => (
                  <DashboardDispatchRow
                    remision={remisionNumber}
                    cliente={bodegaDestination?.client?.nombre}
                    estado={!!remisionFirmadaFile ? "Entregado" : "En transito"}
                  />
                )
              )}
          </div>
        </DashboardCard>
      </div>
    </div>
  );
}

export default MobileDashboard;
