import React from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router";
import Sidebar from "./Sidebar/Sidebar";
import Topbar from "./Topbar/Topbar";

const NessLayout = () => {
  return localStorage.getItem("email") === "app@nessdigital.co" ? (
    <div className="NessLayout">
      <Topbar />
      <Sidebar />
      <Outlet />
    </div>
  ) : (
    <Navigate to="/" />
  );
};

export default NessLayout;
