import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import HeaderBodegas from "../../components/ReactInventory/HeaderBodegas";
import ItemContainer from "../../components/ReactInventory/ItemContainer";
import InventoryRow from "../../components/InventoryRow/InventoryRow";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getLotesApi } from "../../services/lotes";
import Loading from "../../components/Loading/Loading";

function Inventory() {
  const [allProducts, setAllProducts] = useState([]);
  const [isDesc, setIsDesc] = useState(true);
  const [lotesQty, setLotesQty] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);
  const dispatch = useDispatch();

  let filteredData = allProducts.filter((element) =>
    Object.values(element).some((value) => {
      if (typeof value === "object") {
        return JSON.stringify(value)
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      } else if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isSaveToRefresh) {
      setIsLoading(true);
      getLotesApi().then((lotes) => {
        setIsLoading(false);
        const products = [];
        let totalLotes = 0;
        lotes.forEach((item) => {
          if (!item.deletedAt) {
            totalLotes += 1;
            const productIndex = products.findIndex(
              (p) => p._id === item.product._id
            );
            if (productIndex === -1) {
              const newProduct = { ...item.product, stock: [item] };
              products.push(newProduct);
            } else {
              products[productIndex].stock.push(item);
            }
          } else {
            const productIndex = products.findIndex(
              (p) => p._id === item.product._id
            );
            if (productIndex === -1) {
              const newProduct = { ...item.product, stock: [] };
              products.push(newProduct);
            }
          }
        });
        setLotesQty(totalLotes);
        products.sort((a, b) => b.stock.length - a.stock.length);
        setAllProducts(products);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isSaveToRefresh, dispatch]);

  return (
    <Page>
      <Container mainTop={false}>
        <HeaderBodegas
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          totalInventory={lotesQty}
        />
        <div className="flex flex-col items-center">
          {isLoading ? (
            <Loading />
          ) : (
            <ItemContainer>
              <div className="flex justify-end px-4">
                <div
                  className="flex cursor-pointer"
                  onClick={() => {
                    setIsDesc(!isDesc);
                    setAllProducts((prevProducts) => {
                      const sortedProducts = [...prevProducts].sort((a, b) => {
                        return isDesc
                          ? a.stock.length - b.stock.length
                          : b.stock.length - a.stock.length;
                      });
                      return sortedProducts;
                    });
                  }}
                >
                  <p className="text-[10px] font-light mr-1">Ordenar</p>
                  <FontAwesomeIcon icon={`arrow-${isDesc ? "down" : "up"}`} />
                </div>
              </div>
              {filteredData.map((product) => (
                <InventoryRow key={product._id} {...product} />
              ))}
            </ItemContainer>
          )}
        </div>
      </Container>
    </Page>
  );
}

export default Inventory;
