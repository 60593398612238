import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateLayout from "../layout/PrivateLayout/PrivateLayout";
import PublicLayout from "../layout/PublicLayout/PublicLayout";
import Login from "../pages/Login/Login";
import NewSurgery from "../pages/NewSurgery/NewSurgery";
import NotFound from "../pages/NotFound/NotFound";
import Scanner from "../pages/Scanner/Scanner";
import Settings from "../pages/Settings/Settings";
import Surgeries from "../pages/Surgeries/Surgeries";
import Surgery from "../pages/Surgery/Surgery";
import Warehouses from "../pages/ReactInventory/Warehouses";
import Warehouse from "../pages/ReactInventory/Warehouse";
import LotNumbers from "../pages/ReactInventory/LotNumbers";
import LotNumber from "../pages/ReactInventory/LotNumber";
import Shipment from "../pages/ReactInventory/Shipment";
import Shipments from "../pages/ReactInventory/Shipments";
import Ingresos from "../pages/Ingresos/Ingresos";
import NewIngreso from "../components/Ingresos/NewIngreso";
import ProtectedLayout from "../layout/ProtectedLayout";
import MobileLayout from "../layout/MobileLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import MobileProtected from "../layout/Page/MobileProtected";
import MobileDashboard from "../pages/Dashboard/MobileDashboard";
import MobileWarehouses from "../pages/ReactInventory/MobileWarehouses";
import MobileWarehouse from "../pages/ReactInventory/MobileWarehouse";
import MobileLotNumbers from "../pages/ReactInventory/MobileLotNumbers";
import Inventory from "../pages/Inventory/Inventory";
import Onboarding from "../pages/Onboarding";
import { isMobile } from "../utils/core";
import InvitedLayout from "../layout/InvitedLayout";
import Invitar from "../components/Invitar";
import NessLayout from "../layout/NessLayout";
import Egresos from "../pages/Egresos/Egresos";
import NewEgreso from "../pages/Egresos/NewEgreso";
import IngresarLotes from "../pages/IngresarLotes";
import Clientes from "../pages/Clientes";

const AppRoutes = () => {
  if (isMobile()) {
    return (
      <Routes>
        <Route element={<MobileLayout />}>
          <Route element={<MobileProtected />}>
            <Route path="/escaner" element={<Scanner />} />
          </Route>
          <Route path="/" element={<MobileDashboard />} />
          <Route path="/bodegas" element={<MobileWarehouses />} />
          <Route path="/bodegas/:id" element={<MobileWarehouse />} />
          <Route path="/bodegas/:id/lotes/" element={<MobileLotNumbers />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route element={<PublicLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route element={<PrivateLayout />}>
        <Route element={<ProtectedLayout />}>
          <Route path="/ingresos" element={<Ingresos />} />
          <Route path="/ingresos/crear-ingreso" element={<NewIngreso />} />
          <Route path="/ingresar-lotes" element={<IngresarLotes />} />
        </Route>
        <Route path="/" element={<Dashboard />} />
        <Route path="/cirugias" element={<Surgeries />} />
        <Route path="/cirugias/:surgery" element={<Surgery />} />
        <Route path="/cirugias/crear-cirugia" element={<NewSurgery />} />
        <Route
          path="/cirugias/editar-cirugia/:surgery"
          element={<NewSurgery />}
        />
        <Route path="/bodegas" element={<Warehouses />} />
        <Route path="/bodegas/:id" element={<Warehouse />} />
        <Route path="/bodegas/:id/lotes/" element={<LotNumbers />} />
        <Route path="/bodegas/lotes/:id" element={<LotNumber />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/despachos" element={<Shipments />} />
        <Route path="/despachos/:id" element={<Shipment />} />
        <Route path="/inventario" element={<Inventory />} />
        <Route path="/egresos" element={<Egresos />} />
        <Route path="/egresos/:id" element={<NewEgreso />} />
        <Route path="/egresos/crear-egreso" element={<NewEgreso />} />
        <Route path="/ajustes" element={<Settings />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route element={<PublicLayout />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route element={<InvitedLayout />}>
        <Route path="/onboarding" element={<Onboarding />} />
      </Route>
      <Route element={<NessLayout />}>
        <Route path="/invitar" element={<Invitar />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
