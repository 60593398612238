import { useLocation, Link } from "react-router-dom";
import ItemContainer from "../../components/ReactInventory/ItemContainer";
import ItemRow from "../../components/ReactInventory/ItemRow";
import { useEffect, useState } from "react";
import { slugify } from "../../utils/core";
import { FaSearch } from "react-icons/fa";

function MobileWarehouse() {
  let location = useLocation();
  let bodega = location.state?.bodega;
  const { name = "", client = "" } = bodega || {};
  const { nombre = "", numeroId = "" } = client;
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredData(bodega.products);
  }, [bodega]);

  const products = [];
  bodega.stock.forEach((item) => {
    const productIndex = products.findIndex((p) => p._id === item.product._id);
    if (productIndex === -1) {
      const newProduct = { ...item.product, stock: [item] };
      products.push(newProduct);
    } else {
      products[productIndex].stock.push(item);
    }
  });

  bodega.products = products;

  const handleFilter = (event) => {
    const inputValue = event.target.value.toLowerCase();
    const filtered = bodega.products.filter((element) =>
      Object.values(element).some((value) => {
        if (typeof value === "object") {
          return JSON.stringify(value).toLowerCase().includes(inputValue);
        } else if (typeof value === "string") {
          return value.toLowerCase().includes(inputValue);
        }
        return false;
      })
    );
    setFilteredData(filtered);
    setSearchTerm(event.target.value);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    handleFilter(event);
  };

  return (
    <div className="mobile-container h-full bg-white min-h-screen pt-10">
      <div className="flex flex-col items-center">
        <div className="grid grid-rows-3 w-full pb-8 justify-items-center items-center">
          <h1 className="text-2xl sm:text-3xl font-semibold">{name}</h1>
          <p className="text-xs sm:text-xl">
            {nombre} - {numeroId}
          </p>
        </div>
        <div className="w-11/12 pb-10">
          <div className="flex items-center px-4 py-2 border border-cadet-blue-crayola rounded-lg focus-within:border-transparent focus-within:outline outline-blue-700">
            <FaSearch className="fill-cool-grey mr-2" />
            <input
              type="text"
              value={searchTerm}
              onChange={handleChange}
              className="w-full border-none outline-none placeholder:text-cool-grey"
              placeholder="Busca por nombre de producto o código"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <ItemContainer>
          {filteredData?.map((product, index) => (
            <div key={index}>
              <Link
                to={`/bodegas/${slugify(bodega.name)}/lotes`}
                state={{ bodega, product }}
              >
                <ItemRow product={product} />
              </Link>
            </div>
          ))}
        </ItemContainer>
      </div>
    </div>
  );
}

export default MobileWarehouse;
