import { url, axiosPrivate } from ".";

export const getMaterials = async () => {
  try {
    const materials = await axiosPrivate.get(`${url}/materials`);
    return materials.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const createMaterial = async (material) => {
  try {
    const newMaterial = await axiosPrivate.post(`${url}/materials`, {
      ...material,
    });
    return newMaterial.data.data;
  } catch (e) {
    console.log(e);
  }
};
