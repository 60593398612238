import { url, axiosPrivate } from ".";

export const getClientsApi = async () => {
  try {
    const clients = await axiosPrivate.get(`${url}/clients`);
    return clients.data.data;
  } catch (err) {
    console.log(err);
  }
};

export const createClientApi = async (client) => {
  try {
    const newClient = await axiosPrivate.post(`${url}/clients`, {
      ...client,
    });
    return newClient.data.data;
  } catch (err) {
    console.log(err);
  }
};
