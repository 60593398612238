import { url, axiosPrivate } from ".";

export const getEgresosApi = async () => {
  try {
    const egresos = await axiosPrivate.get(`${url}/egresos`);
    return egresos.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const addEgresoApi = async (egreso) => {
  try {
    const newEgreso = await axiosPrivate.post(`${url}/egresos`, egreso, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return newEgreso.data.data;
  } catch (e) {
    console.log(e);
    throw e.response.data;
  }
};

export const updateEgresoFacturaApi = async (egresoId, quoteNumber) => {
  try {
    const updatedEgreso = await axiosPrivate.patch(
      `${url}/egresos/${egresoId}/factura`,
      {
        value: quoteNumber,
      }
    );
    return updatedEgreso.data.data;
  } catch (e) {
    console.log(e);
  }
};
