import { useState } from "react";
import LogoNess from "../components/Icons/Logo/LogoNess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Input from "../components/Input/Input";
import Button from "../components/Button/Button";
import { createOrganization } from "../services/auth";
import Loading from "../components/Loading/Loading";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/appSlice";

function Onboarding() {
  const [step, setStep] = useState(1);
  const [typeId, setTypeId] = useState("");
  const [company, setCompany] = useState("");
  const [numberId, setNumberId] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [fullname, setFullname] = useState("");
  const [next, setNext] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const content = useSelector((state) => state.app.content);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tokenIsValid, invitationToken] = useOutletContext();

  const validatePassword = (password) => {
    if (password.length < 8 || password.length > 16) {
      return "La contraseña debe ser entre 8 y 16 caracteres";
    }
    const requirements = [
      /[A-Z]/, // At least one uppercase letter
      /[a-z]/, // At least one lowercase letter
      /\d/, // At least one digit
      /[^a-zA-Z0-9]/, // At least one special character
      /(123|234|345|456|567|678|789|890)/, // Avoid consecutive numbers
    ];
    for (const requirement of requirements) {
      if (!requirement.test(password)) {
        return "La contraseña no cumple con los requisitos.";
      }
    }
    // Password is valid
    return null;
  };

  const step1AllFieldsComplete =
    company.trim() && typeId && numberId.trim() && city.trim();
  const step2AllFieldsComplete =
    email.trim() &&
    password &&
    passwordConfirm &&
    fullname.trim() &&
    password === passwordConfirm;

  const Step1 = (
    <div className="w-1/2 h-3/4 flex flex-col justify-around">
      <p className="h-12 font-semibold text-5xl mb-2">Bienvenido</p>
      <div>
        <Input
          type="text"
          label="Nombre de la empresa"
          className="!w-3/4"
          value={company}
          validation={next && !company.trim()}
          handleChange={(e) => setCompany(e.target.value)}
        />
        <Input
          type="dropdown"
          value={typeId}
          values={["NIT", "Cedula"]}
          label="Tipo de ID"
          className="!w-3/4"
          validation={next && !typeId}
          onChange={(e) => setTypeId(e.target.value)}
        />
        <Input
          type="text"
          label="Número de ID"
          className="!w-3/4"
          value={numberId}
          validation={next && !numberId.trim()}
          handleChange={(e) => setNumberId(e.target.value)}
        />
        <Input
          type="text"
          label="Ciudad"
          className="!w-3/4"
          value={city}
          validation={next && !city.trim()}
          handleChange={(e) => setCity(e.target.value)}
        />
        <Button
          label="Siguiente"
          className="!w-3/4 !bg-black !mt-8"
          color="black"
          click={() => {
            setNext(true);
            if (step1AllFieldsComplete) {
              setStep(2);
              setNext(false);
            }
          }}
        />
      </div>
    </div>
  );

  const Step2 = isLoading ? (
    <Loading />
  ) : (
    <div className="w-1/2 h-3/4 flex flex-col justify-around relative">
      <div
        className="flex items-center absolute top-[-30px] cursor-pointer"
        onClick={() => setStep(1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="text-xl" />
        <span className="ml-2">Atras</span>
      </div>
      <p className="h-12 font-semibold lg:text-[27px] 2xl:text-4xl mb-2">
        Crear usuario administrador
      </p>
      <div>
        <Input
          label="Correo electrónico"
          className="!w-3/4"
          type="text"
          value={email}
          validation={next && !email.trim()}
          handleChange={(e) => setEmail(e.target.value)}
        />
        <Input
          label="Crea una contraseña"
          sublabel="Entre 8 y 16 caracteres, una minúscula, una mayúscula, un numero y un carácter especial"
          className="!w-3/4"
          type="text"
          value={password}
          validation={next && (!password || validatePassword(password))}
          validationMessage={validatePassword(password)}
          isPassword
          handleChange={(e) => setPassword(e.target.value)}
        />
        <Input
          label="Confirma la contraseña"
          className="!w-3/4"
          type="text"
          value={passwordConfirm}
          validation={
            next && (!passwordConfirm || password !== passwordConfirm)
          }
          validationMessage="La contraseña debe ser igual"
          isPassword
          handleChange={(e) => setPasswordConfirm(e.target.value)}
        />
        <Input
          label="Nombres y apellidos"
          className="!w-3/4"
          type="text"
          value={fullname}
          validation={next && !fullname.trim()}
          handleChange={(e) => setFullname(e.target.value)}
        />
        <Button
          label="Finalizar"
          className="!w-3/4 !bg-black !mt-8"
          color="black"
          click={() => {
            setNext(true);
            setServerError("");
            if (step2AllFieldsComplete && !validatePassword(password)) {
              setIsLoading(true);
              createOrganization({
                company: company.trim(),
                typeId: typeId === "NIT" ? typeId.toLowerCase() : "cc",
                numberId: numberId.trim(),
                city: city.trim(),
                email: email.trim(),
                password,
                fullname: fullname.trim(),
                invitationToken,
              })
                .then(() => {
                  dispatch(login(email, password, content)).then(() => {
                    if (JSON.parse(localStorage.getItem("isLogged"))) {
                      navigate("/");
                    } else {
                      setIsLoading(false);
                    }
                  });
                })
                .catch((err) => {
                  setIsLoading(false);
                  setServerError(err.message);
                });
            }
          }}
        />
        <span className="text-sm text-red-400 block mt-[7px]">
          {serverError}
        </span>
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-2 h-screen">
      <div className="flex justify-center items-center">
        <LogoNess size="xl" />
      </div>
      <div className="flex justify-center items-center bg-[#F5F7F9]">
        {tokenIsValid ? (
          step === 1 ? (
            Step1
          ) : (
            Step2
          )
        ) : (
          <p>El link ha expirado o no es valido</p>
        )}
      </div>
    </div>
  );
}

export default Onboarding;
