import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connectWS, ws } from "../services/websocket";
import {
  loadInitialData,
  openPair,
  setIsSaveToRefresh,
} from "../store/appSlice";
import MobileMenu from "./MobileMenu";
import { setAxiosPrivateHeaders } from "../services";

const MobileLayout = () => {
  const isLogged = useSelector((state) => state.app.isLogged);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);
  const dispatch = useDispatch();

  useEffect(() => {
    setAxiosPrivateHeaders();
    dispatch(setIsSaveToRefresh(true));
    dispatch(loadInitialData());
  }, [dispatch]);

  useEffect(() => {
    if (isLogged) {
      if (!ws || ws.readyState !== WebSocket.OPEN) {
        connectWS("mobile");
        ws.onmessage = (event) => {
          const messageFromServer = event.data;
          console.log("(Mobile App) WS server:", messageFromServer);
          if (messageFromServer === "open_session") {
            dispatch(openPair(true));
          } else if (messageFromServer === "close_session") {
            dispatch(openPair(false));
          }
        };
      }
    }
    return () => {
      ws?.close();
    };
  }, [isLogged, isSaveToRefresh, dispatch]);

  return isLogged ? (
    <div className="MobileLayout">
      <MobileMenu />
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default MobileLayout;
