import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBodegasApi } from "../services/bodegas";
import { setBodegasAndLotes, setFilterBodegasTerm } from "../store/appSlice";
import HeaderBodegas from "./ReactInventory/HeaderBodegas";
import Loading from "./Loading/Loading";
import ItemContainer from "./ReactInventory/ItemContainer";
import { Link } from "react-router-dom";
import { slugify } from "../utils/core";
import BodegaRow from "./ReactInventory/BodegaRow";

const Bodegas = () => {
  const clients = useSelector((state) => state.app.clients);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);
  const allBodegas = useSelector((state) => state.app.bodegas);
  const filterBodegasTerm = useSelector((state) => state.app.filterBodegasTerm);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  let filteredData = allBodegas.filter((element) =>
    Object.values(element).some((value) => {
      if (typeof value === "object") {
        return JSON.stringify(value)
          .toLowerCase()
          .includes(filterBodegasTerm.toLowerCase());
      } else if (typeof value === "string") {
        return value.toLowerCase().includes(filterBodegasTerm.toLowerCase());
      }
      return false;
    })
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isSaveToRefresh && filterBodegasTerm === "") {
      setIsLoading(true);
      getBodegasApi().then((bods) => {
        setIsLoading(false);
        dispatch(setBodegasAndLotes(bods));
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [isSaveToRefresh, dispatch]);

  return (
    <div>
      <HeaderBodegas
        searchTerm={filterBodegasTerm}
        setSearchTerm={(val) => dispatch(setFilterBodegasTerm(val))}
        clientes={clients}
        clients={clients}
      />
      <div className="flex flex-col items-center">
        {isLoading ? (
          <Loading />
        ) : !!filteredData?.length ? (
          <ItemContainer>
            {filteredData
              .slice()
              .sort((a, b) =>
                a.name === "Principal"
                  ? -1
                  : b.name === "Principal"
                  ? 1
                  : a.name.localeCompare(b.name)
              )
              .map((bodega, index) => (
                <div key={index}>
                  <Link
                    to={`/bodegas/${slugify(bodega.name)}`}
                    state={{ bodega }}
                  >
                    <BodegaRow bodega={bodega} />
                  </Link>
                </div>
              ))}
          </ItemContainer>
        ) : (
          <p className="text-center">No hay bodegas creadas</p>
        )}
      </div>
    </div>
  );
};

export default Bodegas;
