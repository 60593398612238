import { useLocation, Link } from "react-router-dom";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import HeaderBodegas from "../../components/ReactInventory/HeaderBodegas";
import ItemContainer from "../../components/ReactInventory/ItemContainer";
import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading/Loading";

function LotNumbers() {
  let location = useLocation();
  const bodegas = useSelector((state) => state.app.bodegas);
  const bodega = bodegas.find((bod) => bod._id === location.state?.bodega._id);
  const product = location.state?.product;
  const lotes = product?.stock;

  const [filteredData, setFilteredData] = useState(lotes);
  const [searchTerm, setSearchTerm] = useState("");

  const handleFilter = (event) => {
    const inputValue = event.target.value.toLowerCase();
    const filtered = lotes.filter((element) =>
      Object.values(element).some((value) => {
        if (typeof value === "object") {
          return JSON.stringify(value).toLowerCase().includes(inputValue);
        } else if (typeof value === "string") {
          return value.toLowerCase().includes(inputValue);
        }
        return false;
      })
    );
    setFilteredData(filtered);
    setSearchTerm(event.target.value);
  };

  return (
    <Page>
      <Container mainTop={false}>
        <HeaderBodegas
          handleFilter={handleFilter}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          product={product}
        />
        <div className="flex flex-col items-center">
          <ItemContainer>
            {!bodega ? (
              <Loading />
            ) : (
              filteredData?.map((lote, index) => (
                <div key={index}>
                  <Link
                    to={`/bodegas/lotes/${lote.lote}`}
                    state={{ lote, bodega }}
                  >
                    <div className="flex justify-between items-center bg-cultured rounded-lg px-8 py-4 mb-4">
                      <div className="flex">
                        <h2 className="w-52 text-lg font-semibold">
                          {lote.inTransit ? (
                            <span className="text-carrot-orange">
                              {lote.lote}
                            </span>
                          ) : (
                            <span>{lote.lote}</span>
                          )}
                        </h2>
                        {lote.inTransit ? (
                          <span className="flex justify-center items-center w-20 h-7 border border-carrot-orange rounded-lg">
                            <span className="text-sm text-carrot-orange font-light">
                              En transito
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="flex justify-end items-center">
                        <div className="h-12 w-px bg-green-300 mr-8"></div>
                        <div className="flex flex-col items-center w-16">
                          <p className="text-xs font-light">
                            {!!lote.expiryDate
                              ? moment(lote.expiryDate)
                                  .utcOffset(0)
                                  .format("DD/MM/YYYY")
                              : "-"}
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            )}
          </ItemContainer>
        </div>
      </Container>
    </Page>
  );
}

export default LotNumbers;
