import React, { useState } from "react";
import moment from "moment";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import SmallInputNumber from "../../common/SmallInputNumber";
import { updateEgresoFacturaApi } from "../../services/egresos";
import Modal from "../Modal/Modal";
import Confirm from "../Modals/Confirm/Confirm";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EgresosList = ({ isLoading, filteredData, setEgresos }) => {
  const navigate = useNavigate();
  const [isEnterQuote, setIsEnterQuote] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [isOpenConfirm, setIsOpenConfirm] = useState("");
  const [quoteLoading, setQuoteLoading] = useState(false);
  const content = useSelector((state) => state.app.content);

  const updateEgresoFactura = async (egresoId, quote) => {
    setQuoteLoading(true);
    const updatedEgreso = await updateEgresoFacturaApi(egresoId, quote);
    setQuoteLoading(false);
    const updatedEgresos = filteredData.map((egreso) => {
      if (egreso._id === updatedEgreso._id) {
        return { ...egreso, ...updatedEgreso };
      }
      return egreso;
    });
    setEgresos(updatedEgresos);
    setIsEnterQuote("");
    setQuoteNumber("");
  };

  return (
    <div className="container mx-auto p-4">
      {isLoading ? (
        <Loading />
      ) : !filteredData.length ? (
        <p className="text-center mt-10">No hay egresos creados</p>
      ) : (
        <table className="mt-8 min-w-full">
          <thead>
            <tr>
              <th className="p-2 text-center underline font-medium">Fecha</th>
              <th className="p-2 text-center underline font-medium">Cliente</th>
              <th className="p-2 text-center underline font-medium">Ciudad</th>
              <th className="p-2 text-center underline font-medium">
                No. Factura
              </th>
              <th className="p-2 text-center underline font-medium">
                Total egresos
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((egreso) => (
              <tr
                key={egreso._id}
                onClick={() =>
                  navigate(`/egresos/${egreso._id}`, {
                    state: { egreso },
                  })
                }
                className="cursor-pointer rounded-[7px] hover:bg-[#f8f9fc] p-[10px]"
              >
                <td className="p-2 text-center font-light text-sm p-[16px]">
                  {moment(egreso.createdAt).format("DD MMMM YYYY")}
                </td>
                <td className="p-2 text-center font-light text-sm">
                  {egreso.client.nombre}
                </td>
                <td className="p-2 text-center font-light text-sm">
                  {egreso.client?.ciudad || "-"}
                </td>
                <td className="p-2 text-center font-light text-sm">
                  {isEnterQuote === egreso._id ? (
                    <SmallInputNumber
                      quoteNumber={quoteNumber}
                      setQuoteNumber={setQuoteNumber}
                      setIsEnterQuote={setIsEnterQuote}
                      isLoading={quoteLoading}
                      onOK={(e) => {
                        e.stopPropagation();
                        quoteNumber > 0 &&
                          updateEgresoFactura(egreso._id, quoteNumber);
                      }}
                      onKeyDown={(e) =>
                        e.key === "Enter" &&
                        quoteNumber > 0 &&
                        updateEgresoFactura(egreso._id, quoteNumber)
                      }
                    />
                  ) : egreso.quoteNumber === 0 ? (
                    <Button
                      label="Agregar factura"
                      size="xxxsmall"
                      variant="action"
                      click={(e) => {
                        e.stopPropagation();
                        setIsEnterQuote(egreso._id);
                      }}
                    />
                  ) : (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOpenConfirm(egreso._id);
                      }}
                    >
                      {egreso.quoteNumber}
                    </span>
                  )}
                </td>
                <td className="p-2 text-center">
                  {egreso.materials.reduce(
                    (accumulator, currentMaterial) =>
                      accumulator + currentMaterial.quantity,
                    0
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal isOpen={isOpenConfirm}>
        <Confirm
          copy={content.texts.CONFIRM_DELETE_QUOTE}
          close={() => setIsOpenConfirm("")}
          confirm={() => {
            updateEgresoFactura(isOpenConfirm, 0);
            setIsOpenConfirm("");
          }}
        />
      </Modal>
    </div>
  );
};

export default EgresosList;
