const EN_Content = {
  status: {
    TRANSIT: "In transit 3",
    SURGERY: "No pending surgeries",
  },
  button: {
    CREATE_BODEGA: "Create bodega",
    CREATE_CLIENT: "Create client",
    CREATE_DISPATCH: "Create delivery",
    CREATE_INGRESO: "Create ingreso",
    CREATE_EGRESO: "Create egreso",
    CREATE_SURGERY: "Create surgery",
    SAVE_SURGERY: "Save surgery",
    SAVE_CHANGES: "Save changes",
  },
  placeholder: {
    SEARCH: "Search surgeries, clients, clinics and more...",
    FILTER: "Filter patients, clinics and more...",
  },
  heading: {
    SETTINGS_PAGE_TITLE: "Settings",
    SETTINGS_PAGE_SUBTITLE: "Application settings",
    SURGERIES_PAGE_SUBTITLE: "Information about all records",
  },
  texts: {
    CLIENT_HELP_TEXT: "Select the client to associate with this clinic",
    CONFIRM_DELETE_QUOTE:
      "Are you sure you want to delete this invoice number?",
    CONFIRM_DELETE_SURGERY: "Are you sure you want to delete this surgery?",
    COMING_SURGERIES: "Coming surgeries",
    EDIT_SURGERY: "Edit surgery",
    LAST_UPDATED: "Last updated",
    NEW_SURGERY: "New surgery",
    NEW_SURGERY_SUMMARY: "Summary of the new surgery",
    NO_SURGERIES: "No surgeries registered",
    PAST_SURGERIES: "Past surgeries",
    CLOSED_SURGERIES: "Closed surgeries",
    PRESS_ENTER: "press ENTER",
    SEARCH_OR_CREATE: "Search or create using the text fields",
    SELECT_LANGUAGE: "Select language",
    SURGERY_DATE_TIME: "Surgery date and time",
    SURGERIES_NODATE: "Surgeries without date",
    SURGERIES_NOSPEND: "Surgeries without gasto",
    TO_DISPATCH: "To dispatch",
    TO_QUOTE: "Pending invoicing",
    TO_SPEND: "To spend",
  },
  labels: {
    ADDRESS: "Address",
    BODEGA: "Bodega",
    CANCEL: "Cancel",
    CITY: "City",
    CLIENT: "Client",
    CLIENTS: "Clients",
    CLINIC: "Clinic",
    CODE: "Code",
    CONFIRM: "Confirm",
    CREATE: "Create",
    CREATED: "Created",
    DATE: "Date",
    DELIVERY: "Delivery",
    DELIVERED: "Delivered",
    EMAIL: "Email",
    ENTER_LOTES: "Enter Lotes",
    ENTITIES: "Entities",
    FILTER: "Filter",
    HOME: "Home",
    ID_NUMBER: "ID number",
    ID_TYPE: "ID type",
    IN_TRANSIT: "In transit",
    LAST_NAMES: "Last names",
    LOGIN: "Login",
    LOGOUT: "Logout",
    LOTE: "Lote",
    MATERIAL: "Material",
    NAME: "Name",
    NAMES: "Names",
    NOTES: "Notes",
    NUMBER: "Number",
    OFFICE_ADDRESS: "Office address",
    PATIENT: "Patient",
    PASSWORD: "Password",
    PENDING: "Pending",
    PRODUCT: "Product",
    QUOTE: "Invoice",
    RECEIVED: "Received",
    REQUESTED_MATERIAL: "Requested material",
    SEARCH: "Search",
    SETTINGS: "Settings",
    SHIPMENTS: "Shipments",
    SPECIALIST: "Specialist",
    SPECIALITY: "Speciality",
    SPEND: "Spend",
    SUMMARY: "Summary",
    SURGERY: "Surgery",
    SURGERIES: "Surgeries",
    TELEPHONE: "Telephone",
    VIEW: "View",
    WAREHOUSES: "Warehouses",
  },
};

export default EN_Content;
