import axios from "axios";

const prod_server_url = "https://api-v2.nessdigital.co/api/v1";
const local_server_url = "http://localhost:4000/api/v1";
// const local_server_url = "https://IPGZ:4000/api/v1";
// const dev_server_url = "https://ness-dev-server.ue.r.appspot.com/api/v1";
const dev_server_url = "http://54.196.221.252/api/v1";
// const dev_server_url = "https://ness-server.ue.r.appspot.com/api/v1";
// const dev_server_url = "https://ness-server.ue.r.appspot.com/api/v1";
// "http://nessdigital-test-env.eba-mjas6hc4.us-east-1.elasticbeanstalk.com/api/v1";

export const url =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? prod_server_url
    : process.env.REACT_APP_ENVIRONMENT === "local"
    ? local_server_url
    : dev_server_url;
console.log("NODE_ENV", process.env.NODE_ENV);
console.log("REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT);

export const axiosPrivate = axios.create({
  baseURL: url,
});

export const setAxiosPrivateHeaders = () => {
  axiosPrivate.interceptors.request.use(
    (config) => {
      if (localStorage.getItem("token")) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getAllUserData = async () => {
  try {
    const userData = await axiosPrivate.get(`${url}/user-data`);
    return userData.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const postNewEntity = async (newEntity, entity) => {
  try {
    const res = await axiosPrivate.post(`${url}/${entity}`, {
      ...newEntity,
    });
    return res.data.data;
  } catch (e) {
    console.log(e);
  }
};
