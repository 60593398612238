import React from "react";
import Topbar from "../Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  loadInitialData,
  populatePageInputValues,
  setIsSaveToRefresh,
  setPageInputs,
  setSurgeriesTabs,
} from "../../store/appSlice";
import { getPageInputs } from "../../content/elements/NewSurgery/pageInputs";
import { setAxiosPrivateHeaders } from "../../services";
import "./PrivateLayout.css";

const PrivateLayout = () => {
  const isLogged = useSelector((state) => state.app.isLogged);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);
  const content = useSelector((state) => state.app.content);
  const surgeriesTabs = useSelector((state) => state.app.surgeriesTabs);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setAxiosPrivateHeaders();
    dispatch(setIsSaveToRefresh(true));
  }, [dispatch]);

  // TODO: what to do when user refreshes browser: get all user data
  useEffect(() => {
    if (isLogged) {
      if (!surgeriesTabs.length) {
        dispatch(setSurgeriesTabs(content));
      }
      if (!isSaveToRefresh && location.pathname !== "/") {
        dispatch(loadInitialData()).then(() => {
          dispatch(setPageInputs(getPageInputs(content)));
          dispatch(populatePageInputValues());
        });
      }
    }
  }, [
    dispatch,
    content,
    isSaveToRefresh,
    isLogged,
    location.pathname,
    surgeriesTabs.length,
  ]);

  return isLogged ? (
    <div className="PrivateLayout">
      <Topbar />
      <Sidebar />
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateLayout;
