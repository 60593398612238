import { useRef, useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/fa";

import DropdownSearch from "../DropdownSearch/DropdownSearch";
import Button from "../Button/Button";
import { addBodegaApi } from "../../services/bodegas";
import { addBodega } from "../../store/appSlice";
import { useDispatch, useSelector } from "react-redux";

const Modal = ({ toggleModal, clients }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [nombreBodega, setNombreBodega] = useState("");
  const [bodegaCiudad, setBodegaCiudad] = useState("");
  const [bodegaClient, setBodegaClient] = useState("");
  const modalRef = useRef(null);
  const user = useSelector((state) => state.app.user);
  const bodegas = useSelector((state) => state.app.bodegas);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        toggleModal();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        toggleModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [toggleModal]);

  useEffect(() => {
    setErrorMsg("");
  }, [nombreBodega]);

  return (
    <div className="modal">
      <div
        ref={modalRef}
        className="flex flex-col gap-6 w-[412px] border-2 border-black bg-white p-5 rounded-lg"
      >
        <div className="flex justify-end">
          <FaTimesCircle className="cursor-pointer" onClick={toggleModal} />
        </div>
        <h2 className="text-2xl font-semibold -mt-3">Crear bodega</h2>
        <div>
          <h3 className="text-lg font-semibold mb-2">
            <span className="text-cerulean-blue">*</span> Nombre
          </h3>
          <input
            type="text"
            value={nombreBodega}
            onChange={(e) => setNombreBodega(e.target.value)}
            className="w-[346px] h-[38px] px-2 py-1 border border-cadet-blue-crayola rounded-lg"
          />
          <div className="text-red-500 text-sm mt-2 mb-[-5px]">{errorMsg}</div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">
            <span className="text-cerulean-blue">*</span> Ciudad
          </h3>
          <input
            type="text"
            value={bodegaCiudad}
            onChange={(e) => setBodegaCiudad(e.target.value)}
            className="w-[346px] h-[38px] px-2 py-1 border border-cadet-blue-crayola rounded-lg"
          />
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">
            <span className="text-cerulean-blue">*</span> Cliente
          </h3>
          <DropdownSearch
            label="Cliente"
            findField="nombre"
            findField2="numeroId"
            prependField2="tipoId"
            placeholder="Selecciona el cliente"
            suggestions={clients}
            hideTitle
            onSelect={(e, client) => {
              e.stopPropagation();
              setBodegaClient(client);
            }}
          />
        </div>
        <div className="w-[330px] flex justify-center gap-3 mt-10">
          <Button variant="cancel" label="Cancelar" click={toggleModal} />
          <Button
            disabled={
              nombreBodega === "" ||
              bodegaClient === "" ||
              bodegaCiudad === "" ||
              errorMsg
            }
            variant="confirm"
            isLoading={isLoading}
            label="Crear bodega"
            click={async () => {
              if (
                bodegas.some(
                  (b) =>
                    b.client._id === bodegaClient._id &&
                    b.name.toLowerCase() === nombreBodega.trim().toLowerCase()
                )
              ) {
                setErrorMsg("Este cliente ya tiene una bodega con este nombre");
                return;
              }
              setErrorMsg("");
              setIsLoading(true);

              const addedBodega = await addBodegaApi({
                name: nombreBodega,
                city: bodegaCiudad,
                client: bodegaClient._id,
                organization: user.organization._id,
              });
              setIsLoading(false);
              dispatch(addBodega(addedBodega));
              toggleModal();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
