import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import HeaderLote from "../../components/ReactInventory/HeaderLote";
// import LoteLog from "../../components/ReactInventory/LoteLog";
import Button from "../../components/ReactInventory/Button";
import { useState } from "react";
import DropdownSearch from "../../components/DropdownSearch/DropdownSearch";
import Confirm from "../../components/Modals/Confirm/Confirm";
import Modal from "../../components/Modal/Modal";
import { createFileApi } from "../../services/files";
import { addDespacho, setBodegasAndLotes } from "../../store/appSlice";
import { addLeadingZeros } from "../../utils";
import { FILE_TYPES } from "../../constants";

function LotNumber() {
  const [selectedBodega, setSelectedBodega] = useState();
  const [transferUrl, setTransferUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [remisionNumber, setRemisionNumber] = useState("");
  const [confirmTransfer, setConfirmTransfer] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();

  const { bodega, lote } = location.state;
  let bodegas = useSelector((state) => state.app.bodegas);
  bodegas = bodegas
    .filter((bod) => bod._id !== bodega._id)
    .map((bod) => ({
      ...bod,
      client: bod.client.nombre,
    }));

  const createDespacho = async () => {
    const despacho = {
      origin: bodega.city,
      destination: selectedBodega.city,
      lotes: [{ ...lote, bodegaId: bodega._id, clientId: bodega.client._id }],
      bodegaDestination: selectedBodega._id,
    };
    const despachoResponse = await createFileApi(
      FILE_TYPES.remisiones,
      despacho
    );
    const { createdDespacho, updatedValues, url } = despachoResponse;
    setTransferUrl(url);
    setRemisionNumber(createdDespacho.remisionNumber);
    dispatch(addDespacho(createdDespacho));
    dispatch(setBodegasAndLotes(updatedValues));
  };

  return (
    <Page>
      <Container mainTop={false}>
        <div className="flex flex-col gap-14">
          <HeaderLote inTransit={lote.inTransit} />
          <div>
            <h2 className="text-2xl font-medium mb-3">
              {!!lote.inTransit ? "En transito a" : "Bodega actual"}
            </h2>
            <p className="text-lg font-semibold">{bodega.name}</p>
            <p className="text-xs font-light">
              {bodega.client.nombre} - {bodega.client.numeroId}
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-medium mb-3">Trasladar a</h2>
            <DropdownSearch
              disabled={!!lote.inTransit}
              findField="name"
              findField2="client"
              placeholder="Seleccionar bodega"
              suggestions={bodegas}
              onSelect={(e, item) => {
                e.stopPropagation();
                setSelectedBodega(item);
              }}
              selectedItem={selectedBodega}
            />
          </div>
          <div>
            <h2 className="text-2xl font-medium mb-3">Notas</h2>
            <textarea
              name="notes"
              id="notes"
              className="resize-none w-[461px] h-[104px] p-2 border border-cadet-blue-crayola rounded-lg text-sm font-light"
            ></textarea>
          </div>
          {/* <div>
            <h2 className="text-2xl font-medium mb-3">Registro</h2>
            <LoteLog />
          </div> */}
          <div className="w-[461px] flex justify-end gap-3 mt-10">
            <Button
              variant="cancel"
              label="Cancelar"
              onClick={() => navigate(-1)}
            />
            <span>
              <Button
                disabled={lote.inTransit}
                variant="confirm"
                label="Guardar"
                onClick={() => setConfirmTransfer(true)}
              />
            </span>
          </div>
        </div>
      </Container>
      <Modal isOpen={confirmTransfer}>
        <Confirm
          copy="Estas seguro de realizar este despacho?"
          note={{ copy: "Esto generara una remision", color: "#EE8D1C" }}
          close={() => setConfirmTransfer(false)}
          confirm={async () => await createDespacho()}
        />
      </Modal>
      <Modal isOpen={confirmTransfer}>
        <Confirm
          copy="Estas seguro de realizar este despacho?"
          note={{ copy: "Esto generara una remision", color: "#EE8D1C" }}
          close={() => setConfirmTransfer(false)}
          isLoading={isLoading}
          confirm={async () => {
            setIsLoading(true);
            await createDespacho();
            setIsLoading(false);
            setConfirmTransfer(false);
          }}
        />
      </Modal>
      <Modal isOpen={transferUrl}>
        <Confirm
          copy="Despacho realizado!"
          note={{
            copy: `Se genero la remision No. ${addLeadingZeros(
              remisionNumber
            )}`,
          }}
          confirmLabel="Ver"
          closeLabel="Cerrar"
          close={() => {
            setTransferUrl("");
            navigate(-1);
          }}
          confirm={() => {
            var win = window.open(transferUrl, "_blank");
            win.focus();
          }}
        />
      </Modal>
    </Page>
  );
}

export default LotNumber;
