import React, { useEffect, useState } from "react";
import Page from "../layout/Page/Page";
import Container from "../components/Container/Container";
import { useDispatch, useSelector } from "react-redux";
import ClientesList from "../components/Clientes/ClientesList";
import { createClientApi, getClientsApi } from "../services/clients";
import { addClient, setCreateClientModal } from "../store/appSlice";
import Modal from "../components/Modal/Modal";
import ClientesHeader from "../components/Clientes/ClientesHeader";
import Input from "../components/Input/Input";

const Clientes = () => {
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [telephone, setTelephone] = useState("");
  const [idType, setIdType] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [city, setCity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const stateClients = useSelector((state) => state.app.clients);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);
  const isModalOpen = useSelector((state) => state.app.isCreateClientModalOpen);
  const dispatch = useDispatch();

  let filteredData = clients.filter((element) =>
    Object.values(element).some((value) => {
      if (typeof value === "object") {
        return JSON.stringify(value)
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      } else if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isSaveToRefresh) {
      setIsLoading(true);
      getClientsApi().then((clientes) => {
        setIsLoading(false);
        setClients(clientes);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isSaveToRefresh]);

  useEffect(() => {
    setClients((prevClients) => {
      if (prevClients !== stateClients) {
        return stateClients;
      }
      return prevClients;
    });
  }, [stateClients]);

  return (
    <Page>
      <Container mainTop={false}>
        <ClientesHeader
          filteredData={filteredData}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          title="Clientes"
        />
        <ClientesList filteredData={filteredData} isLoading={isLoading} />
      </Container>
      {isModalOpen && (
        <Modal isOpen={isModalOpen}>
          <Modal.Container
            title="Crear cliente"
            close={() => {
              dispatch(setCreateClientModal(false));
            }}
            onAccept={() => {
              setIsCreateLoading(true);
              createClientApi({
                nombre: name.trim(),
                tipoId: idType === "NIT" ? "nit" : "cc",
                numeroId: idNumber.trim(),
                ciudad: city.trim(),
                direccion: address.trim(),
                telefono: telephone.trim(),
                email: email.trim(),
              }).then((newClient) => {
                setIsCreateLoading(false);
                setName("");
                setIdType("");
                setIdNumber("");
                setEmail("");
                setAddress("");
                setCity("");
                setTelephone("");
                dispatch(setCreateClientModal(false));
                dispatch(addClient(newClient));
              });
            }}
            isAcceptLoading={isCreateLoading}
            withFooter
            isPrimaryBtnDisabled={!(!name || !idType || !idNumber || !city)}
          >
            <Input
              label="Nombre del cliente"
              isMandatory
              value={name}
              handleChange={(e) => setName(e.target.value)}
              type="text"
            />
            <Input
              label="Tipo ID"
              isMandatory
              type="dropdown"
              value={idType}
              onChange={(e) => setIdType(e.target.value)}
              values={["NIT", "Cédula"]}
            />
            <Input
              label="Numero ID"
              isMandatory
              type="text"
              value={idNumber}
              handleChange={(e) => setIdNumber(e.target.value)}
            />
            <Input
              label="Ciudad"
              isMandatory
              type="text"
              value={city}
              handleChange={(e) => setCity(e.target.value)}
            />
            <Input
              label="Direccion"
              type="text"
              value={address}
              handleChange={(e) => setAddress(e.target.value)}
            />
            <Input
              label="Telefono"
              type="text"
              value={telephone}
              handleChange={(e) => setTelephone(e.target.value)}
            />
            <Input
              label="Correo electronico"
              type="text"
              value={email}
              handleChange={(e) => setEmail(e.target.value)}
            />
          </Modal.Container>
        </Modal>
      )}
    </Page>
  );
};

export default Clientes;
