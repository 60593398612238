const ItemRow = ({ product }) => {
  return (
    <div>
      <div className="flex justify-between items-center bg-cultured rounded-lg p-3 sm:px-8 sm:py-4 mb-4">
        <div>
          <h2 className="text-xs sm:text-lg font-semibold">{product.nombre}</h2>
          <p className="text-xs font-light">{product.codigo}</p>
        </div>
        <div className="flex justify-end items-center">
          <div className="h-12 w-px bg-green-300 mr-1 sm:mr-8"></div>
          <div className="flex flex-col items-center w-14 sm:w-16">
            <p className="text-lg sm:text-xl font-semibold">
              {product.stock.length}
            </p>
            <p className="text-xs font-light">Unidades</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemRow;
