import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import DashboardCard from "../../components/DashboardCard/DashboardCard";
import QuantityCircle from "../../components/QuantityCircle/QuantityCircle";
import DashboardRow from "../../components/DashboardRow/DashboardRow";
import DashboardDispatchRow from "../../components/DashboardRow/DashboardDispatchRow";
import DashboardSurgeryRow from "../../components/DashboardRow/DashboardSurgeryRow";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadInitialData } from "../../store/appSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Dashboard() {
  const allSurgeries = useSelector((state) => state.app.surgeries);
  let bodegas = useSelector((state) => state.app.bodegas);
  bodegas = [...bodegas].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  const clients = useSelector((state) => state.app.clients);
  const lotes = useSelector((state) => state.app.lotes);
  const totalLotes = useSelector((state) => state.app.totalLotes);
  const despachos = useSelector((state) => state.app.despachos);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);
  const [isSoonSurgeries, setIsSoonSurgeries] = useState(false);
  const [surgeries, setSurgeries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isSaveToRefresh) {
      dispatch(loadInitialData()).then(() => {
        setIsLoading(false);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isSaveToRefresh, dispatch]);

  useEffect(() => {
    let filtered = allSurgeries;
    if (!isSoonSurgeries) {
      filtered = filtered.filter((s) => s.fileSpend);
    } else {
      filtered = filtered.filter(
        (s) => s.fecha_cx && new Date(s.fecha_cx) > new Date() && !s.fileSpend
      );
    }
    filtered.sort((a, b) => new Date(b.fecha_cx) - new Date(a.fecha_cx));
    setSurgeries(filtered);
  }, [allSurgeries, isSoonSurgeries]);

  const SkeletonLoader = ({ count = 5, spacing = 20 }) => (
    <SkeletonTheme baseColor="#f1f1f1" highlightColor="#fbfbfb">
      <Skeleton
        height={30}
        count={count}
        style={{
          marginBottom: `${spacing}px`,
          zIndex: 0,
          borderRadius: 6,
        }}
      />
    </SkeletonTheme>
  );

  return (
    <Page>
      <Container mainTop={false}>
        <div className="grid grid-cols-3 grid-rows-2 gap-4">
          <div className="col-span-2">
            <DashboardCard
              title="Cirugías"
              quantity={surgeries.length}
              isSoonSurgeries={isSoonSurgeries}
              toggleList={() => setIsSoonSurgeries(!isSoonSurgeries)}
            >
              <div className="w-11/12 flex flex-col gap-y-2">
                {isLoading ? (
                  <SkeletonLoader />
                ) : (
                  surgeries
                    .slice(0, 5)
                    .map(
                      ({
                        _id,
                        city,
                        clinic,
                        patient,
                        fecha_cx,
                        cliente,
                        fileSpend,
                        despachos,
                        quoteNumber,
                      }) => (
                        <DashboardSurgeryRow
                          key={_id}
                          city={city}
                          clinic={clinic}
                          patient={patient}
                          date={fecha_cx}
                          clientName={cliente.nombre}
                          spent={!!fileSpend}
                          dispatched={!!despachos.length}
                          invoice={quoteNumber > 0}
                        />
                      )
                    )
                )}
              </div>
            </DashboardCard>
          </div>
          <div className="col-start-1 row-start-2">
            <DashboardCard title="Bodegas" quantity={bodegas.length}>
              <div className="w-11/12 flex flex-col gap-y-2">
                {isLoading ? (
                  <SkeletonLoader />
                ) : (
                  bodegas
                    .slice(0, 5)
                    .map(({ _id, city, name, stock, client }) => (
                      <DashboardRow
                        key={_id}
                        firstLine1={name}
                        firstLine2={city}
                        secondLine={client.nombre}
                        thirdLine={`${stock.length} ${
                          stock.length === 1 ? "lote" : "lotes"
                        }`}
                      />
                    ))
                )}
              </div>
            </DashboardCard>
          </div>
          <div className="col-start-2 row-start-2">
            <DashboardCard title="Clientes" quantity={clients.length}>
              <div className="w-11/12 flex flex-col gap-y-2">
                {isLoading ? (
                  <SkeletonLoader />
                ) : (
                  clients
                    .slice(0, 5)
                    .map(({ nombre, tipoId, numeroId, ciudad }) => (
                      <DashboardRow
                        key={numeroId}
                        firstLine1={nombre}
                        secondLine={`${tipoId.toUpperCase()}. ${numeroId}`}
                        thirdLine={ciudad}
                      />
                    ))
                )}
              </div>
            </DashboardCard>
          </div>
          <div className="col-start-3 row-start-1">
            <DashboardCard title="Lotes">
              <QuantityCircle quantity={lotes.length} label="Disponibles" />
              <div className="w-full flex justify-around">
                <div className="text-center">
                  <h3 className="font-medium text-[40px] leading-none">
                    {totalLotes}
                  </h3>
                  <p className="text-sm">Usados</p>
                </div>
                <div className="text-center">
                  <h3 className="font-medium text-[40px] leading-none">
                    {lotes.filter((l) => l.inTransit).length}
                  </h3>
                  <p className="text-sm">En transito</p>
                </div>
              </div>
            </DashboardCard>
          </div>
          <div className="order-2 lg:col-start-3 lg:row-start-2">
            <DashboardCard title="Despachos">
              <div className="w-full flex justify-around items-center">
                <QuantityCircle
                  quantity={despachos.length}
                  label="Realizados"
                />
                <div className="text-center">
                  <h3 className="font-medium text-[40px] leading-none">
                    {despachos.filter((d) => !d.remisionFirmadaFile).length}
                  </h3>
                  <p className="text-sm">En transito</p>
                </div>
              </div>
              <div className="w-11/12 flex flex-col gap-y-2 mt-5">
                {isLoading ? (
                  <SkeletonLoader count={3} spacing={10} />
                ) : (
                  despachos
                    .slice(0, 3)
                    .map(
                      ({
                        _id,
                        remisionNumber,
                        remisionFirmadaFile,
                        bodegaDestination,
                      }) => (
                        <DashboardDispatchRow
                          key={_id}
                          remision={remisionNumber}
                          cliente={bodegaDestination?.client?.nombre}
                          estado={
                            !!remisionFirmadaFile ? "Entregado" : "En transito"
                          }
                        />
                      )
                    )
                )}
              </div>
            </DashboardCard>
          </div>
        </div>
      </Container>
    </Page>
  );
}

export default Dashboard;
