const InventoryRow = ({ nombre, stock, codigo }) => {
  return (
    <div className="rounded-lg px-4 py-3 hover:bg-cultured">
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold">{nombre}</h2>
        <p className="text-2xl font-semibold">{stock.length}</p>
      </div>
      <p className="text-xs font-light">{codigo}</p>
    </div>
  );
};

export default InventoryRow;
