import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import HeaderDespacho from "../../components/ReactInventory/HeaderDespacho";
import ResumenCard from "../../components/ReactInventory/ResumenCard";
import DespachoRow from "../../components/ReactInventory/DespachoRow";
import Searchbar from "../../components/Searchbar/Searchbar";
import { createFileApi } from "../../services/files";
import { addDespacho, setBodegasAndLotes } from "../../store/appSlice";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modal/Modal";
import Confirm from "../../components/Modals/Confirm/Confirm";
import { addLeadingZeros } from "../../utils";
import { FILE_TYPES } from "../../constants";

function Shipment() {
  let location = useLocation();
  const path = location.pathname.split("/")[2];
  const today = new Date();
  const [bodega, setBodega] = useState(location.state?.bodega || {});
  const { client = "", stock = "" } = bodega;

  const [lastClickedIndex, setLastClickedIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedBodega, setSelectedBodega] = useState("");
  const [bodegaOrigin, setBodegaOrigin] = useState(bodega);
  const [searchTerm, setSearchTerm] = useState("");
  const [despachoResponse, setDespachoResponse] = useState("");
  const [filteredStock, setFilteredStock] = useState(stock);
  const [reverse, setReverse] = useState(false);
  const [bodegaDestination, setBodegaDestination] = useState({});
  const [confirmCreateDespacho, setConfirmCreateDespacho] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stock !== location.state?.bodega) {
      setFilteredStock(stock);
    }
  }, [bodega, stock, location]);

  function handleSelect(item, firstIndex) {
    setSelected((prev) => ({
      ...prev,
      [item._id]: !selected[item._id],
    }));
    let index = selectedItems.findIndex(
      (i) => i.codigo === item.codigo && i._id === item._id
    );

    if (index === -1) {
      setSelectedItems([...selectedItems, { ...item, selected: true }]);
    } else {
      let newSelectedItems = [...selectedItems];
      newSelectedItems[index].selected = !newSelectedItems[index].selected;
      newSelectedItems.splice(index, 1);
      setSelectedItems(newSelectedItems);
    }
    setLastClickedIndex(firstIndex);
  }

  function onShiftClick(index) {
    const start = Math.min(index, lastClickedIndex);
    const end = Math.max(index, lastClickedIndex);
    const itemsInSelectedRange = filteredStock
      .slice(start, end + 1)
      .filter((item) => !selected[item._id])
      .map((item) => ({ ...item, selected: true }));

    const updatedSelected = itemsInSelectedRange.reduce((obj, key) => {
      obj[key._id] = true;
      return obj;
    }, {});
    setSelected((prev) => ({
      ...prev,
      ...updatedSelected,
    }));
    setSelectedItems((prev) => [...prev, ...itemsInSelectedRange]);
  }

  const handleClose = () => {
    setDespachoResponse("");
    setConfirmCreateDespacho(false);
    setSelectedItems([]);
    setSelected({});
  };

  return (
    <Page>
      <Container mainTop={false}>
        <div className="flex flex-col gap-11">
          <HeaderDespacho
            bodega={bodega}
            cliente={client}
            reverse={reverse}
            setReverse={setReverse}
            onSwap={({ origin, destination }) => {
              setSelectedItems([]);
              setSelected({});
              setBodegaDestination(destination);
              setFilteredStock(origin.stock);
              setBodegaOrigin(origin);
            }}
            selectedBodega={selectedBodega}
            setSelectedBodega={(e, selectedBodega) => {
              e.stopPropagation();
              setSelectedBodega(selectedBodega);
              if (reverse) {
                setFilteredStock(selectedBodega.stock);
                setBodegaOrigin(selectedBodega);
              } else {
                setBodegaDestination(selectedBodega);
              }
            }}
          />
          <div className="w-full h-px bg-cadet-blue-crayola"></div>
          <div className="flex justify-between">
            <div className="w-3/5 flex flex-col">
              <Searchbar
                placeholder="Busca por lote, nombre de producto o código"
                handleChange={(e) => {
                  let updatedSearchTerm = e.target.value;
                  setSearchTerm(updatedSearchTerm);
                  let allStock = reverse ? selectedBodega.stock : stock;
                  let filteredItems = allStock.filter((stock) => {
                    const query = updatedSearchTerm.toLowerCase();
                    return (
                      stock.product.codigo.toLowerCase().indexOf(query) >= 0 ||
                      stock.product.nombre.toLowerCase().indexOf(query) >= 0 ||
                      stock.lote.toLowerCase().indexOf(query) >= 0
                    );
                  });
                  setFilteredStock(filteredItems);
                }}
                leftIcon
                isGlobal={false}
                clearSearch={() => {
                  setSearchTerm("");
                  setFilteredStock(reverse ? selectedBodega.stock : stock);
                }}
                searchTerm={searchTerm}
              />
              {filteredStock?.length ? (
                [...filteredStock]
                  .sort((a, b) => a.inTransit - b.inTransit)
                  .map((item, index) => (
                    <DespachoRow
                      key={index}
                      index={index}
                      item={item}
                      onShiftClick={onShiftClick}
                      onSelect={handleSelect}
                      selected={selected[item._id]}
                    />
                  ))
              ) : (
                <p className="w-4/5 mt-20 text-center">
                  No hay lotes para mostrar
                </p>
              )}
            </div>
            <div className="w-2/5 flex flex-col items-end">
              <ResumenCard
                selectedItems={selectedItems}
                onSelect={handleSelect}
                today={today}
                path={path}
                bodega={bodega}
                bodegaDestination={bodegaDestination}
                disabled={!selectedBodega || !selectedItems.length}
                isLoading={isLoading}
                onSave={async () => {
                  setConfirmCreateDespacho(true);
                }}
              />
            </div>
          </div>
        </div>
      </Container>
      <Modal isOpen={confirmCreateDespacho}>
        <Confirm
          copy="Estas seguro de realizar este despacho?"
          note={{ copy: "Esto generara una remision", color: "#EE8D1C" }}
          close={() => setConfirmCreateDespacho(false)}
          isLoading={isLoading}
          confirm={async () => {
            setIsLoading(true);
            const despacho = {
              origin: bodegaOrigin.city,
              destination: bodegaDestination.city,
              bodegaDestination: bodegaDestination._id,
              date: today,
              lotes: selectedItems.map((lote) => ({
                ...lote,
                bodegaId: bodegaOrigin._id,
                clientId: bodegaOrigin.client._id,
              })),
            };
            const addedDespacho = await createFileApi(
              FILE_TYPES.remisiones,
              despacho
            );
            setIsLoading(false);
            const { createdDespacho, url, updatedValues } = addedDespacho;
            setDespachoResponse({
              url,
              remisionNumber: createdDespacho.remisionNumber,
            });
            setBodega(updatedValues.find((b) => b._id === bodega._id));
            setSearchTerm("");
            dispatch(addDespacho(createdDespacho));
            dispatch(setBodegasAndLotes(updatedValues));
          }}
        />
      </Modal>
      {!!despachoResponse && (
        <Modal isOpen={!!despachoResponse}>
          <Confirm
            copy="Despacho realizado!"
            note={{
              copy: `Se genero la remision No. ${addLeadingZeros(
                despachoResponse.remisionNumber
              )}`,
            }}
            confirmLabel="Ver"
            closeLabel="Cerrar"
            close={() => handleClose()}
            confirm={() => {
              var win = window.open(despachoResponse.url, "_blank");
              win.focus();
            }}
          />
        </Modal>
      )}
    </Page>
  );
}

export default Shipment;
