export const getObjectLength = (obj) => {
  return Object.keys(obj).length;
};

export const slugify = (str) => {
  return str
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "");
};

export function isMobile() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return true;
  } else if (/android/i.test(userAgent)) {
    return true;
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
}

export const isNumber = (value) => {
  // Regular expression to match only numbers
  const regex = /^[0-9]*$/;
  if (regex.test(value)) {
    return true;
  }
  return false;
};
