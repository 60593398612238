import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import HeaderBodegas from "../../components/ReactInventory/HeaderBodegas";
import DespachosMenu from "../../components/ReactInventory/DespachosMenu";
import DespachosRow from "../../components/ReactInventory/DespachosRow";
import Loading from "../../components/Loading/Loading";
import { useEffect, useState } from "react";
import { getDespachos } from "../../services/despachos";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faClock } from "@fortawesome/free-regular-svg-icons";

function Shipments() {
  const [despachos, setDespachos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTransitTab, setIsTransitTab] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);

  let filteredData = despachos.filter((d) =>
    isTransitTab ? d.status === "En transito" : d.status === "Entregado"
  );
  filteredData = filteredData.filter(
    (despacho) =>
      despacho.remisionNumber?.toString().includes(searchTerm.toLowerCase()) ||
      despacho?.bodegaDestination.client.nombre
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase()) >= 0
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isSaveToRefresh) {
      setIsLoading(true);
      getDespachos().then((ds) => {
        setIsLoading(false);
        let parsedDespachos = ds?.map((d) => ({
          ...d,
          status: d?.remisionFirmadaFile ? "Entregado" : "En transito",
        }));
        setDespachos(parsedDespachos);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isSaveToRefresh]);

  const updateDespachos = (updatedDespachos) => {
    const parsedDespachos = updatedDespachos.map((d) => ({
      ...d,
      status: d?.remisionFirmadaFile ? "Entregado" : "En transito",
    }));
    setDespachos(parsedDespachos);
  };

  return (
    <Page>
      <Container mainTop={false}>
        <HeaderBodegas searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <div className="Tabs">
          <span
            className={`${isTransitTab ? "surgeries-tab-selected" : ""}`}
            onClick={() => setIsTransitTab(true)}
          >
            <FontAwesomeIcon icon={faClock} className="tab-icon" />
            En transito
          </span>
          <span
            className={`${!isTransitTab ? "surgeries-tab-selected" : ""}`}
            onClick={() => setIsTransitTab(false)}
          >
            <FontAwesomeIcon icon={faCalendarCheck} className="tab-icon" />
            Entregado
          </span>
        </div>
        <div className="w-full">
          {isLoading ? (
            <Loading />
          ) : filteredData?.length ? (
            <>
              <DespachosMenu />
              {filteredData.map((despacho) => (
                <DespachosRow
                  key={despacho._id}
                  despacho={despacho}
                  updateDespachos={(val) => updateDespachos(val)}
                />
              ))}
            </>
          ) : (
            <p className="text-center">No hay despachos creados</p>
          )}
        </div>
      </Container>
    </Page>
  );
}

export default Shipments;
