import axios from "axios";
import { url } from ".";

export const checkLogin = async (email, password) => {
  try {
    const loggedUser = await axios.post(`${url}/auth/login`, {
      email,
      password,
    });
    return loggedUser.data;
  } catch (e) {
    throw e;
  }
};

export const signup = async (data) => {
  try {
    const newUser = await axios.post(`${url}/auth/signup`, {
      ...data,
    });
    return newUser.data;
  } catch (e) {
    throw e;
  }
};

export const generateInvitationEmail = async (data) => {
  try {
    const invitation = await axios.post(`${url}/auth/signup-invitation`, {
      ...data,
    });
    return invitation.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const checkInvitationToken = async (token) => {
  try {
    const checkInvitation = await axios.post(`${url}/auth/check-invitation`, {
      token,
    });
    return checkInvitation.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const createOrganization = async (data) => {
  try {
    const newOrganization = await axios.post(`${url}/auth/organization`, {
      ...data,
    });
    return newOrganization.data;
  } catch (err) {
    throw err.response.data;
  }
};
