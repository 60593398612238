const QuantityCircle = ({ quantity, label, isSmall = false }) => {
  const styles = {
    parent: isSmall ? `w-[100px] h-[100px] p-2` : `w-[134px] h-[134px] p-2.5`,
    child: isSmall ? `text-[10px]` : `text-[13px]`,
  };

  return (
    <div
      className={`${styles.parent} rounded-full bg-gradient-to-t from-[#882890] to-[#5B6AEE]`}
    >
      <div className="w-full h-full rounded-full flex flex-col justify-center items-center bg-white">
        <h3 className="font-medium text-5xl">{quantity}</h3>
        <p className={styles.child}>{label}</p>
      </div>
    </div>
  );
};

export default QuantityCircle;
