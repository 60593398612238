import { url, axiosPrivate } from ".";

export const getSurgeries = async () => {
  try {
    const surgeries = await axiosPrivate.get(`${url}/surgeries`);
    return surgeries.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getSurgeryApi = async (id) => {
  try {
    const surgery = await axiosPrivate.get(`${url}/surgeries/${id}`);
    return surgery.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const addSurgery = async (surgery) => {
  try {
    const newSurgery = await axiosPrivate.post(`${url}/surgeries`, {
      surgery,
    });
    return newSurgery.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateSurgeryApi = async (surgery, surgeryId) => {
  try {
    const updatedSurgery = await axiosPrivate.put(
      `${url}/surgeries/${surgeryId}`,
      {
        surgery,
      }
    );
    return updatedSurgery.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const deleteSurgeryApi = async (surgeryId) => {
  try {
    const updatedSurgeries = await axiosPrivate.delete(
      `${url}/surgeries/${surgeryId}`
    );
    return updatedSurgeries.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateSurgeryLotes = async (surgeryId, materials) => {
  try {
    const updatedSurgery = await axiosPrivate.patch(
      `${url}/surgeries/${surgeryId}/lotes`,
      { materials }
    );
    return updatedSurgery.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateSurgeryStatus = async (surgery, status, value) => {
  try {
    const updatedSurgery = await axiosPrivate.patch(
      `${url}/surgeries/${surgery._id}/status`,
      {
        surgery,
        status,
        value,
      }
    );
    return updatedSurgery.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const enterSurgeryQuotationNumber = async (surgeryId, quoteNumber) => {
  try {
    const updatedSurgery = await axiosPrivate.patch(
      `${url}/surgeries/${surgeryId}/quote`,
      {
        value: quoteNumber,
      }
    );
    return updatedSurgery.data.data;
  } catch (e) {
    console.log(e);
  }
};

export const spendSurgeryApi = async (surgeryId, formData) => {
  try {
    const fileUploaded = await axiosPrivate.post(
      `${url}/surgeries/${surgeryId}/gasto`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return fileUploaded.data.data;
  } catch (e) {
    console.log(e);
    throw e.response.data;
  }
};
