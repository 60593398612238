import React from "react";
import { useDispatch } from "react-redux";
import { handleModalInputValue } from "../../store/appSlice";
import "./Dropdown.css";

const Dropdown = ({ label, values, value, id, className, onChange }) => {
  const dispatch = useDispatch();

  return (
    <div className="Dropdown">
      <select
        className={className}
        name={label}
        value={value}
        id={label}
        onChange={(e) =>
          onChange
            ? onChange(e)
            : dispatch(handleModalInputValue({ value: e.target.value, id }))
        }
      >
        <option disabled selected value="">
          Selecciona una opcion
        </option>
        {values?.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
