import { FaTimesCircle } from "react-icons/fa";
import Button from "../Button/Button";
import { createMaterial } from "../../services/materials";
import { useEffect, useState } from "react";
import { addMaterial } from "../../store/appSlice";
import { useDispatch } from "react-redux";

const AddReferencia = ({
  newCodigo,
  newDescription,
  setNewReferenciaModal,
  setNewDescription,
  setNewCodigo,
  materials,
  selectNewReferencia,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setErrorMsg("");
  }, [newCodigo]);

  return (
    <div className="modal">
      <div className="flex flex-col gap-6 w-[412px] border-2 border-black bg-white p-5 rounded-lg">
        <div className="flex justify-end">
          <FaTimesCircle
            className="cursor-pointer"
            onClick={() => setNewReferenciaModal(false)}
          />
        </div>
        <h2 className="text-2xl font-semibold -mt-3">Crear referencia</h2>
        <div>
          <h3 className="text-lg font-semibold mb-2">
            <span className="text-cerulean-blue">*</span> Código
          </h3>
          <input
            type="text"
            value={newCodigo}
            onChange={(e) => setNewCodigo(e.target.value)}
            className="w-[346px] h-[38px] px-2 py-1 border border-cadet-blue-crayola rounded-lg"
          />
          <span className="text-red-500 text-sm">{errorMsg}</span>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">
            <span className="text-cerulean-blue">*</span> Nombre o descripción
          </h3>
          <input
            type="text"
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            className="w-[346px] h-[38px] px-2 py-1 border border-cadet-blue-crayola rounded-lg"
          />
        </div>
        <div className="confirm-footer">
          <Button
            variant="cancel"
            label="Cancelar"
            click={() => setNewReferenciaModal(false)}
          />
          <Button
            disabled={!newCodigo || !newDescription || errorMsg}
            variant="confirm"
            isLoading={isLoading}
            label="Crear"
            click={async () => {
              if (materials.some((m) => m.codigo === newCodigo)) {
                setErrorMsg("Este código ya existe");
                return;
              }
              setErrorMsg("");
              setIsLoading(true);
              createMaterial({
                codigo: newCodigo,
                nombre: newDescription,
              }).then((newMaterial) => {
                dispatch(addMaterial(newMaterial));
                selectNewReferencia(newCodigo, newDescription, newMaterial._id);
                setNewCodigo("");
                setNewDescription("");
                setIsLoading(false);
                setNewReferenciaModal(false);
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddReferencia;
