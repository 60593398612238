import React, { useState } from "react";
import Loading from "../Loading/Loading";
import Modal from "../Modal/Modal";
import Confirm from "../Modals/Confirm/Confirm";
import { useSelector } from "react-redux";

const ClientesList = ({ isLoading, filteredData }) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState("");
  const content = useSelector((state) => state.app.content);

  return (
    <div className="container mx-auto p-4">
      {isLoading ? (
        <Loading />
      ) : !filteredData.length ? (
        <p className="text-center mt-10">No hay clientes creados</p>
      ) : (
        <table className="mt-8 min-w-full">
          <thead>
            <tr>
              <th className="p-2 text-center underline font-medium">Nombre</th>
              <th className="p-2 text-center underline font-medium">ID</th>
              <th className="p-2 text-center underline font-medium">Ciudad</th>
              {/* <th className="p-2 text-center underline font-medium">
                Telefono
              </th> */}
            </tr>
          </thead>
          <tbody>
            {filteredData
              .sort((a, b) => a.nombre.localeCompare(b.nombre))
              .map((client) => (
                <tr
                  key={client._id}
                  className="rounded-[7px] p-[10px] hover:bg-cultured"
                >
                  <td className="p-2 text-left font-light text-sm">
                    {client.nombre}
                  </td>
                  <td className="p-2 text-center font-light text-sm">
                    {client.tipoId.toUpperCase()}. {client.numeroId}
                  </td>
                  <td className="p-2 text-center font-light text-sm">
                    {client.ciudad || "-"}
                  </td>
                  {/* <td className="p-2 text-center font-light text-sm">
                    {client.telefono || "-"}
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      )}
      <Modal isOpen={isOpenConfirm}>
        <Confirm
          copy={content.texts.CONFIRM_DELETE_QUOTE}
          close={() => setIsOpenConfirm("")}
          confirm={() => {
            setIsOpenConfirm("");
          }}
        />
      </Modal>
    </div>
  );
};

export default ClientesList;
