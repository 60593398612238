import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useDispatch, useSelector } from "react-redux";
import { openPair } from "../../store/appSlice";
import { ws } from "../../services/websocket";
import "./Scanner.css";

// https://github.com/react-qr-reader/react-qr-reader
// https://github.com/KhaosArbiter/modern-react-qr-reader

const Scanner = () => {
  const [showScanner, setShowScanner] = useState(false);
  const [alreadyScanned, setAlreadyScanned] = useState(false);
  const [awaiting, setAwaiting] = useState(false);
  const [code, setCode] = useState("");
  const [codes, setCodes] = useState([]);
  const isPairOpen = useSelector((state) => state.app.isPairOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.onmessage = (event) => {
        const messageFromServer = event.data;
        if (messageFromServer === "desktop_on") {
          dispatch(openPair(true));
        } else if (messageFromServer === "desktop_off") {
          dispatch(openPair(false));
        }
      };
    }
  }, [dispatch]);

  return (
    <div className="Scanner">
      <div className="scanner-header">
        <h1>Escanear</h1>
        <p>Escanea el codigo del producto</p>
      </div>
      <div className="scanner-main">
        <div className="camera-container">
          <div className="camera">
            <div className="video">
              {showScanner && isPairOpen && (
                <QrScanner
                  onError={(error) => console.log(error?.message)}
                  onDecode={(result) => {
                    if (!awaiting) {
                      if (!!result) {
                        setAwaiting(true);
                        if (codes.includes(result)) {
                          setAlreadyScanned(true);
                          setCode("Este lote ya ha sido escaneado");
                        } else {
                          setAlreadyScanned(false);
                          setCodes((prev) => [...prev, result]);
                          setCode(result);
                        }
                      }
                    }
                  }}
                />
              )}
              {code !== "" && (
                <div className="scanner-success">
                  {alreadyScanned ? (
                    <span className="warning-wrapper">
                      <img
                        src="./assets/images/warning.png"
                        alt="warning icon"
                      />
                    </span>
                  ) : (
                    <span className="tick-wrapper">
                      <img src="./assets/images/tick.svg" alt="tick icon" />
                    </span>
                  )}
                  <p>Lote escaneado</p>
                  <p className="code">{code}</p>
                  <Button
                    label="OK"
                    click={() => {
                      setAwaiting(false);
                      const parts = code.split("+");
                      const keys = [
                        "id",
                        "lote",
                        "x",
                        "expiryDate",
                        "c",
                        "loteCode",
                        "tt",
                        "description",
                      ];
                      const resultObject = keys.reduce((obj, key, index) => {
                        obj[key] = parts[index + 1];
                        return obj;
                      }, {});
                      if (!alreadyScanned && resultObject.lote) {
                        ws.send(
                          JSON.stringify({
                            ...resultObject,
                            type: "scanned_lote",
                          })
                        );
                      }
                      setCode("");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="scanner-footer">
        <div
          className="action-wrapper"
          onClick={() => {
            setShowScanner(true);
          }}
        >
          <img src="./assets/images/scanner.svg" alt="scanner" />
        </div>
        <p>Abrir escaner</p>
      </div>
    </div>
  );
};

export default Scanner;
