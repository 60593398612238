const wsUrl =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? // ? "wss://api.nessdigital.co:4040"
      "wss://api-v2.nessdigital.co"
    : process.env.REACT_APP_ENVIRONMENT === "local"
    ? "ws://localhost:4000"
    : "ws://54.196.221.252";
// : "wss://ness-server.ue.r.appspot.com:4040";
// "wss://ness-dev-server.ue.r.appspot.com:4040";
// "ws://nessdigital-test-env.eba-mjas6hc4.us-east-1.elasticbeanstalk.com:4040";

export let ws = null;

export const connectWS = (device) => {
  ws = new WebSocket(
    `${wsUrl}?token=${localStorage.getItem("token")}&device=${device}`
  );
};
