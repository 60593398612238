import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import InputDate from "../InputDate/InputDate";
import InputSearch from "../InputSearch/InputSearch";
import InputSearch2 from "../InputSearch/InputSearch2";
import InputText from "../InputText/InputText";
import InputTextarea from "../InputTextarea/InputTextarea";
import "./Input.css";

const Input = (props) => {
  const {
    label,
    sublabel,
    type,
    isMandatory,
    size,
    validation,
    validationMessage,
    createNew,
    newLabel,
  } = props;

  const renderInput = (type) => {
    switch (type) {
      case "text":
        return <InputText {...props} />;
      case "textarea":
        return <InputTextarea {...props} />;
      case "search":
        return <InputSearch {...props} />;
      case "search2":
        return <InputSearch2 {...props} />;
      case "date":
        return <InputDate {...props} />;
      case "dropdown":
        return <Dropdown {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className="Input">
      <label className={size}>
        {isMandatory && <span className="isMandatory-star">*</span>}
        {label && (
          <>
            {label}
            {sublabel && (
              <p className={`text-[.65em] font-light !w-3/4 mb-[-20px]`}>
                {sublabel}
              </p>
            )}
            <br />
          </>
        )}
        {renderInput(type)}
      </label>
      {validation && (
        <p className="text-sm mb-[-10px] mt-[2px] text-red-400">
          {validationMessage || "Este campo es requerido"}
        </p>
      )}
      {newLabel && (
        <p
          className="text-xs mt-[3px] cursor-pointer w-fit underline text-[#1155cb]"
          onClick={createNew}
        >
          {newLabel}
        </p>
      )}
    </div>
  );
};

export default Input;
