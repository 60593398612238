import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import FullPage from "../../layout/Page/FullPage";
import "./Surgery.css";
import "./HeaderAction.css";
import "./MaterialCard.css";
import "./DeliverMaterial.css";
import "./SpendSurgery.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Modal from "../../components/Modal/Modal";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import HeaderAction from "./HeaderAction";
import MaterialCard from "./MaterialCard";
import DeliverMaterial from "./DeliverMaterial";
import SpendSurgery from "./SpendSurgery";
import AddLotes from "../../components/Modals/AddLotes/AddLotes";
import SelectBodega from "../../components/Modals/SelectBodega/SelectBodega";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDespachoApi } from "../../services/despachos";
import { createFileApi } from "../../services/files";
import Confirm from "../../components/Modals/Confirm/Confirm";
import Loading from "../../components/Loading/Loading";
import { getSurgeryApi, updateSurgeryLotes } from "../../services/surgeries";
import Despacho from "../../components/Modals/Despacho/Despacho";
import { addLeadingZeros } from "../../utils";
import { setBodegasAndLotes } from "../../store/appSlice";
import { FILE_TYPES } from "../../constants";

let formEntriesData = [
  {
    id: Math.random().toString(16).slice(2),
    identifier: "date",
    label: "Fecha",
    value: "",
    type: "date",
    isMandatory: true,
  },
  {
    id: Math.random().toString(16).slice(2),
    identifier: "origin",
    label: "Ciudad de origen",
    value: "",
    type: "text",
    isMandatory: true,
  },
  {
    id: Math.random().toString(16).slice(2),
    identifier: "destination",
    label: "Ciudad de destino",
    value: "",
    type: "text",
    isMandatory: true,
  },
  {
    id: Math.random().toString(16).slice(2),
    identifier: "courier",
    label: "Transportadora",
    value: "",
    type: "text",
  },
  {
    id: Math.random().toString(16).slice(2),
    identifier: "guideNumber",
    label: "Numero de guia",
    value: "",
    type: "text",
  },
  {
    id: Math.random().toString(16).slice(2),
    identifier: "dateDelivery",
    label: "Fecha de entrega",
    value: "",
    type: "date",
  },
];

const Surgery = () => {
  const [surgery, setSurgery] = useState({});
  const { paciente, clinica, cliente, especialista } = surgery;
  const [materials, setMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [showAddMaterial, setShowAddMaterial] = useState(false);
  const [showAddDespacho, setShowAddDespacho] = useState(false);
  const [showAddBodega, setShowAddBodega] = useState(false);
  const [isCreateBodega, setIsCreateBodega] = useState(false);
  const [selectBodega, setSelectBodega] = useState("");
  const [selectClient, setSelectClient] = useState("");
  const [despachos, setDespachos] = useState([]);
  const [selectedLotes, setSelectedLotes] = useState([]);
  const [formEntries, setFormEntries] = useState(formEntriesData);
  const [currentFormEntries, setCurrentFormEntries] = useState([]);
  const [swapLote, setSwapLote] = useState(null);
  const [despachoLotes, setDespachoLotes] = useState([]);
  const [isNewDespacho, setIsNewDelivery] = useState(true);
  const [confirmCreateDispatch, setConfirmCreateDispatch] = useState(false);
  const [isDespachoDone, setIsDespachoDone] = useState(false);
  const [bodegaDestination, setBodegaDestination] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingLote, setIsLoadingLote] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [despachoSelected, setDespachoSelected] = useState({});
  const [showBodegaMsg, setShowBodegaMsg] = useState(false);
  const [remisionNumber, setRemisionNumber] = useState("");
  const [remisionUrl, setRemisionUrl] = useState("");
  const bodegas = useSelector((state) => state.app.bodegas);
  const lotesData = useSelector((state) => state.app.lotes);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isSaveToRefresh) {
      const surgeryId = params.surgery || location.state.surgery._id;
      getSurgeryApi(surgeryId).then((surgery) => {
        // FIXME: this is a hack to wait for the data to be populated
        setTimeout(() => {
          setDespachos(surgery.despachos);
          let lotes = surgery.material_solicitado.flatMap((item) => item.lotes);
          lotes = lotesData.filter((l) => lotes.find((lo) => lo._id === l._id));
          setSelectedLotes(lotes);
          let parsedMaterialSolicitado = surgery.material_solicitado.map(
            (material) => ({
              ...material,
              lotes: material.lotes?.map((ml) =>
                lotes.find((ld) => ld._id === ml._id)?.clientId !==
                surgery.cliente?._id
                  ? {
                      ...ml,
                      needToBeDispatched: true,
                    }
                  : ml
              ),
            })
          );
          setMaterials(parsedMaterialSolicitado);
          setSurgery(surgery);
          setIsLoading(false);
        }, 1000);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [params.surgery, location, lotesData, isSaveToRefresh]);

  const navigateToEditSurgery = () => {
    navigate(`/cirugias/editar-cirugia/${surgery._id}`, {
      state: { surgery },
    });
  };

  const createDespacho = async (despacho) => {
    const despachoResponse = await createFileApi(FILE_TYPES.gastos, despacho);
    const { createdDespacho, url, updatedValues } = despachoResponse;
    setRemisionUrl(url);
    despacho._id = createdDespacho._id;
    despacho.remisionFile = createdDespacho.remisionFile;
    const updatedDespachos = [...despachos, despacho];
    setDespachos(updatedDespachos);
    setRemisionNumber(createdDespacho.remisionNumber);
    let updatedMaterials = materials.map((material) => ({
      ...material,
      lotes: material.lotes?.map((ml) =>
        !ml.inTransit && selectedLotes.find((dl) => dl._id === ml._id)?.selected
          ? {
              ...ml,
              inTransit: true,
              needToBeDispatched:
                selectedLotes.find((ld) => ld._id === ml._id)?.clientId !==
                surgery.cliente?._id,
            }
          : selectedLotes.find((ld) => ld._id === ml._id)?.clientId !==
            surgery.cliente?._id
          ? {
              ...ml,
              needToBeDispatched: true,
            }
          : ml
      ),
    }));

    let updatedSelectedLotes = selectedLotes.map((sl) => ({
      ...sl,
      hasDespacho:
        sl.hasDespacho || despacho.lotes.find((l) => l._id === sl._id),
    }));
    setSelectedLotes(updatedSelectedLotes);

    let updatedDespachoLotes = despachoLotes.map((dl) => ({
      ...dl,
      hasDespacho:
        dl.hasDespacho || despacho.lotes.find((l) => l._id === dl._id),
    }));
    setDespachoLotes(updatedDespachoLotes);
    setMaterials(updatedMaterials);
    dispatch(setBodegasAndLotes(updatedValues));
  };

  const updateDespacho = async () => {
    const updatedFields = formEntries.filter(
      (cfe) =>
        !cfe.isMandatory &&
        cfe.value !==
          currentFormEntries
            .filter((fe) => !fe.isMandatory)
            .find((fe) => fe.identifier === cfe.identifier)?.value
    );

    let despachoToUpdate = despachoSelected;
    updatedFields.forEach((uf) => {
      despachoToUpdate[uf.identifier] = uf.value;
    });
    await updateDespachoApi(despachoToUpdate, despachoSelected._id);
    setShowAddDespacho(false);
  };

  const updateMaterialLotes = async (updatedMaterials) => {
    setIsLoadingLote(true);
    try {
      const updatedMaterialLotes = await updateSurgeryLotes(
        surgery._id,
        updatedMaterials
      );
      let lotesToDispatch = updatedMaterialLotes.flatMap((item) => item.lotes);
      lotesToDispatch = lotesData.filter((l) =>
        lotesToDispatch.find((lo) => lo._id === l._id)
      );
      setSelectedLotes(lotesToDispatch);
      let parsedMaterialSolicitado = updatedMaterialLotes.map((material) => ({
        ...material,
        lotes: material.lotes?.map((ml) =>
          lotesToDispatch.find((ld) => ld._id === ml._id)?.clientId !==
          surgery.cliente?._id
            ? {
                ...ml,
                needToBeDispatched: true,
              }
            : ml
        ),
      }));
      setMaterials(parsedMaterialSolicitado);
      setSurgery(() => ({
        ...surgery,
        material_solicitado: surgery.material_solicitado.map((ms) => ({
          ...ms,
          lotes: lotesToDispatch.filter(
            (l) => l.product._id === ms.producto._id
          ),
        })),
      }));
    } catch (err) {
      console.error("Ness Error:", err);
    } finally {
      setIsLoadingLote(false);
    }
  };

  return (
    <FullPage>
      {isLoading && Object.keys(surgery).length < 1 ? (
        <Loading />
      ) : (
        <div className="Surgery">
          <div className="surgery-page-header">
            <div className="header-main">
              {/* FIXME: DECIDE WHAT TO USE FOR CX ID  */}
              <span>ID de cirugia: {surgery._id?.substring(0, 8)}</span>
              <p className="surgery-page-patient">
                {paciente.nombres} {paciente.apellidos}
              </p>
              <p className="surgery-page-patient-id">
                {paciente.tipo_id} {paciente.numero_id}
              </p>
            </div>
            <div className="header-progress-wrapper">
              <div className="header-progress-labels">
                <span></span>
                <span></span>
                <span>Fecha de cirugía</span>
              </div>
              <div className="header-progress">
                {surgery.fecha_cx ? (
                  moment(surgery.fecha_cx).format("DD MMMM YYYY")
                ) : !surgery.fileSpend ? (
                  <span
                    className="choose-field"
                    onClick={navigateToEditSurgery}
                  >
                    Elegir fecha
                  </span>
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          </div>
          <main className="surgery-page-main">
            <div className="surgery-page-section">
              <HeaderAction
                heading="Información general"
                action={
                  <Button
                    click={navigateToEditSurgery}
                    disabled={surgery.fileSpend}
                    label="Editar información"
                    size="xxsmall"
                  />
                }
              />
              <div className="general-info">
                <div>
                  <p>Clínica</p>
                  <span>
                    {clinica ? (
                      clinica.nombre
                    ) : !surgery.fileSpend ? (
                      <span
                        className="choose-field"
                        onClick={navigateToEditSurgery}
                      >
                        Elegir clinica
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
                <div>
                  <p>Ciudad</p>
                  <span>{clinica?.ciudad || "-"}</span>
                </div>
                <div>
                  <p>Cliente</p>
                  <span>
                    {cliente ? (
                      cliente.nombre
                    ) : !surgery.fileSpend ? (
                      <span
                        className="choose-field"
                        onClick={navigateToEditSurgery}
                      >
                        Elegir cliente
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
                <div>
                  <p>Identificación</p>
                  <span>
                    {cliente?.numeroId
                      ? `${cliente?.tipoId.toUpperCase()}. ${cliente?.numeroId}`
                      : "-"}
                  </span>
                </div>
                <div>
                  <p>Especialista</p>
                  <span>
                    {especialista ? (
                      `${especialista?.nombres} ${especialista?.apellidos}`
                    ) : !surgery.fileSpend ? (
                      <span
                        className="choose-field"
                        onClick={navigateToEditSurgery}
                      >
                        Elegir especialista
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
                <div>
                  <p>Notas</p>
                  <span>{surgery.notes || "-"}</span>
                </div>
              </div>
            </div>
            <div className="surgery-page-section">
              <HeaderAction
                heading="Material"
                action={
                  <Button
                    click={navigateToEditSurgery}
                    disabled={surgery.fileSpend}
                    label="Editar material"
                    size="xxsmall"
                  />
                }
              />
              <div className="surgery-material-main">
                <div className="material-cards">
                  {materials?.length > 0
                    ? materials.map((material) => (
                        <MaterialCard
                          key={material._id}
                          material={material}
                          hasFileSpend={!!surgery.fileSpend}
                          materialGastado={surgery.materialGastado}
                          isLoading={isLoadingLote}
                          selectedLotes={selectedLotes}
                          selectedMaterial={selectedMaterial}
                          isSurgery
                          onSelect={() => {
                            setSelectedMaterial(material);
                            setShowAddMaterial(true);
                            setSwapLote(null);
                          }}
                          onSwap={(lote) => {
                            setSelectedMaterial(material);
                            setShowAddMaterial(true);
                            setSwapLote(lote);
                          }}
                          onDelete={(lote) => {
                            setSelectedMaterial(material);
                            let updatedMaterials = materials.map((material) => {
                              if (material.lotes) {
                                return {
                                  ...material,
                                  lotes: material?.lotes.filter(
                                    (l) => l._id !== lote._id
                                  ),
                                };
                              }
                              return material;
                            });
                            updateMaterialLotes(updatedMaterials);
                            setSurgery(() => ({
                              ...surgery,
                              material_solicitado: updatedMaterials,
                            }));
                          }}
                        />
                      ))
                    : !surgery.fileSpend && (
                        <p
                          onClick={navigateToEditSurgery}
                          className="choose-field"
                        >
                          Elegir material
                        </p>
                      )}
                </div>
                <div className="material-info">
                  <div className="quantity-circle">
                    <p className="quantity-number">
                      {materials?.reduce((acc, cur) => {
                        return acc + cur.quantity;
                      }, 0)}
                    </p>
                    <p>Unidades</p>
                  </div>
                  <div>
                    <div className="material-info-card">
                      <p>Bodega destino</p>
                      {surgery.fileSpend ? (
                        selectBodega || "-"
                      ) : (
                        <p
                          className="material-main-copy"
                          style={{ color: !cliente && "#b1b1b1" }}
                          onClick={(e) => {
                            if (cliente) {
                              e.stopPropagation();
                              setShowAddBodega(true);
                            }
                          }}
                        >
                          {selectBodega || "Seleccionar bodega"}
                          <FontAwesomeIcon icon="chevron-right" />
                        </p>
                      )}
                      <p className="material-bottom-copy">{selectClient}</p>
                    </div>
                    {!cliente && (
                      <p className="label-info">
                        Antes de seleccionar bodega tienes que{" "}
                        <span onClick={navigateToEditSurgery}>
                          elegir un cliente
                        </span>
                      </p>
                    )}
                    {showBodegaMsg && (
                      <p className="label-info">
                        Antes de crear un despacho tienes que seleccionar una
                        bodega destino
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="surgery-page-section">
              <HeaderAction
                heading="Despacho"
                action={
                  <Button
                    click={() => {
                      if (!selectBodega) {
                        setShowBodegaMsg(true);
                      } else {
                        setShowAddDespacho(true);
                        setIsNewDelivery(true);
                        setFormEntries(formEntriesData);
                      }
                    }}
                    disabled={surgery.fileSpend}
                    label="Crear despacho"
                    size="xxsmall"
                  />
                }
              />
              {despachos?.length > 0 ? (
                despachos.map((despacho) => (
                  <DeliverMaterial
                    key={despacho._id}
                    hasFileSpend={!!surgery.fileSpend}
                    despacho={despacho}
                    editDelivery={(despacho) => {
                      let loadEntries = formEntries.map((entry) => ({
                        ...entry,
                        value:
                          entry.type === "date"
                            ? moment(despacho[entry.identifier]).format(
                                "YYYY-MM-DDTkk:mm"
                              )
                            : despacho[entry.identifier] || "",
                      }));
                      setDespachoSelected(despacho);
                      setFormEntries(loadEntries);
                      setCurrentFormEntries(
                        loadEntries.filter((fe) => !fe.isMandatory)
                      );
                      setIsNewDelivery(false);
                      setShowAddDespacho(true);
                    }}
                  />
                ))
              ) : (
                <div className="no-despacho">
                  <div>
                    <FontAwesomeIcon icon={faPenToSquare} />
                    <p>No hay despachos creados</p>
                  </div>
                </div>
              )}
            </div>
            <div className="surgery-page-section">
              <HeaderAction heading="Gasto" />
              <SpendSurgery
                hasFileSpend={!!surgery.fileSpend}
                fileSpend={surgery.fileSpend}
                surgeryId={surgery._id}
                usedLotes={materials.reduce((acc, item) => {
                  if (item.lotes) {
                    const lotes = item.lotes
                      .filter((lote) => !lote.deletedAt)
                      .map((lote) => lote._id);
                    acc.push(...lotes);
                  }
                  return acc;
                }, [])}
                hasLoteInTransit={selectedLotes.find((sl) => sl.inTransit)}
                hasLoteGastado={
                  !selectedLotes.length &&
                  materials.map((m) => m.lotes.find((l) => l.deletedAt))
                }
                hasLoteToDispatch={selectedLotes.some(
                  (sl) => sl.clientId !== surgery.cliente._id
                )}
                setSurgery={setSurgery}
              />
            </div>
          </main>
        </div>
      )}
      <Modal isOpen={showAddBodega}>
        <Modal.Container
          title="Seleccionar bodega"
          fitHeight
          close={() => setShowAddBodega(false)}
          onAccept={() => setIsCreateBodega(false)}
          withFooter={isCreateBodega}
        >
          <SelectBodega
            bodegas={bodegas.filter(
              (bodega) => bodega?.client?._id === surgery?.cliente?._id
            )}
            client={cliente}
            onSelect={(bodega, client) => {
              setBodegaDestination(bodega._id);
              setSelectBodega(bodega.name);
              setSelectClient(client);
              setShowAddBodega(false);
              setShowBodegaMsg(false);
            }}
            onCancel={() => setShowAddBodega(false)}
          />
        </Modal.Container>
      </Modal>
      <Modal isOpen={showAddDespacho}>
        <Modal.Container
          title={`${isNewDespacho ? "Crear" : "Editar"} despacho`}
          acceptLabel={!isNewDespacho && "Guardar"}
          close={() => setShowAddDespacho(false)}
          columns={2}
          onAccept={() =>
            isNewDespacho ? setConfirmCreateDispatch(true) : updateDespacho()
          }
          isPrimaryBtnDisabled={
            isNewDespacho
              ? formEntries
                  .filter((entry) => entry.isMandatory)
                  .every(
                    (entry) => entry.value !== undefined && entry.value !== ""
                  ) && selectedLotes.some((dp) => dp.selected && !dp.inTransit)
              : currentFormEntries.some(
                  (cfe) =>
                    cfe.value !==
                    formEntries
                      .filter((fe) => !fe.isMandatory)
                      .find((fe) => fe.identifier === cfe.identifier)?.value
                )
          }
          withFooter
        >
          <Despacho
            formEntries={formEntries}
            setFormEntries={setFormEntries}
            isNewDespacho={isNewDespacho}
            despachoLotes={despachoLotes}
            despachoSelected={despachoSelected}
            setDespachoLotes={setSelectedLotes}
            selectedLotes={selectedLotes}
            client={surgery?.cliente}
          />
        </Modal.Container>
      </Modal>
      {/* TODO: FIND OUT WHAT'S BETTER, HIDE/SHOW WITH EXPRESSION OR USING A PROP/CLASS? */}
      <Modal isOpen={showAddMaterial}>
        <Modal.Container
          title={`${swapLote ? "Cambiar" : "Seleccionar"} lote`}
          close={() => {
            setShowAddMaterial(false);
          }}
        >
          <AddLotes
            selectedMaterial={selectedMaterial}
            lotesData={lotesData.filter(
              (lote) => lote.product._id === selectedMaterial.producto?._id
            )}
            selectedLotes={selectedLotes}
            bodegasData={bodegas}
            materials={materials}
            updateMaterialLotes={updateMaterialLotes}
            setShowAddMaterial={setShowAddMaterial}
            swapLote={swapLote}
          />
        </Modal.Container>
      </Modal>
      <Modal isOpen={confirmCreateDispatch}>
        <Confirm
          copy="Estas seguro de realizar este despacho?"
          note={{ copy: "Esto generara una remision", color: "#EE8D1C" }}
          close={() => setConfirmCreateDispatch(false)}
          isLoading={confirmLoading}
          confirm={async () => {
            setConfirmLoading(true);
            let entries = formEntries.reduce((acc, { identifier, value }) => {
              acc[identifier] = value;
              return acc;
            }, {});
            entries.lotes = selectedLotes.filter(
              (despachoLote) =>
                despachoLote.selected &&
                despachoLote._id &&
                !despachoLote.inTransit &&
                despachoLote.bodegaId !== bodegaDestination
            );
            entries.surgery = surgery._id;
            entries.patient = surgery.paciente._id;
            entries.bodegaDestination = bodegaDestination;
            await createDespacho(entries);
            setConfirmLoading(false);
            setShowAddDespacho(false);
            setConfirmCreateDispatch(false);
            setIsDespachoDone(true);
          }}
        />
      </Modal>
      <Modal isOpen={isDespachoDone}>
        <Confirm
          copy="Despacho realizado!"
          note={{
            copy: `Se genero la remision No. ${addLeadingZeros(
              remisionNumber
            )}`,
          }}
          confirmLabel="Ver"
          closeLabel="Cerrar"
          close={() => {
            setIsDespachoDone(false);
            setShowAddDespacho(false);
          }}
          confirm={() => {
            setShowAddDespacho(false);
            var win = window.open(remisionUrl, "_blank");
            win.focus();
          }}
        />
      </Modal>
    </FullPage>
  );
};

export default Surgery;
