const DashboardCard = ({
  title,
  quantity,
  toggleList,
  isSoonSurgeries,
  children,
}) => {
  return (
    <div className="flex flex-col">
      <div className="title h-[66px] flex justify-between items-center bg-anti-flash-white rounded-lg p-3 mb-4">
        <div className="flex items-center">
          <h2 className="font-semibold text-[25px]">{title}</h2>
          {title === "Cirugías" && (
            <div className="hidden w-[42px] h-[42px] lg:flex justify-center items-center bg-raisin-black rounded-full ml-4">
              <p className="font-medium text-[22px] text-white">{quantity}</p>
            </div>
          )}
        </div>
        {quantity !== undefined && (
          <div
            className={`w-[42px] h-[42px] ${
              title === "Cirugías" && "lg:hidden"
            } flex justify-center items-center bg-raisin-black rounded-full`}
          >
            <p className="font-medium text-[22px] text-white">{quantity}</p>
          </div>
        )}
        {title === "Cirugías" && (
          <div className="hidden h-8 lg:flex text-xs border-[1.5px] rounded-md cursor-pointer">
            <div
              className={`${
                isSoonSurgeries ? "bg-white" : "bg-raisin-black"
              } flex justify-center items-center rounded-bl-md rounded-tl-md p-2.5`}
              onClick={() => toggleList()}
            >
              <p
                className={`${
                  isSoonSurgeries ? "text-raisin-black" : "text-white"
                }`}
              >
                Cerradas
              </p>
            </div>
            <div
              className={`${
                isSoonSurgeries ? "bg-raisin-black" : "bg-white"
              } flex justify-center items-center rounded-tr-md rounded-br-md p-2.5`}
              onClick={() => toggleList()}
            >
              <p
                className={`${
                  isSoonSurgeries ? "text-white" : "text-raisin-black"
                }`}
              >
                Próximas
              </p>
            </div>
          </div>
        )}
      </div>
      <div
        className={`content min-h-[224px] lg:min-h-[284px] flex ${
          title === "Lotes" ? "lg:flex-col justify-around" : "flex-col mt-4"
        } items-center`}
      >
        {children}
      </div>
    </div>
  );
};

export default DashboardCard;
