import React, { useState } from "react";
import Input from "./Input/Input";
import Page from "../layout/Page/Page";
import Container from "./Container/Container";
import Button from "./Button/Button";
import { generateInvitationEmail } from "../services/auth";

const Invitar = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const origin = window.location.origin;

  return (
    <Page>
      <Container title="Invitar" subtitle="Organizacion a unirse a Ness">
        <div className="w-64">
          <Input
            type="text"
            size="small"
            label="Correo electronico"
            value={email}
            handleChange={(e) => setEmail(e.target.value)}
          />
          <Button
            label="Enviar"
            disabled={!email}
            isLoading={loading}
            className="h-10"
            click={() => {
              setLoading(true);
              setMessage("");
              generateInvitationEmail({ email, origin })
                .then((res) => {
                  setEmail("");
                  setMessage(res.data);
                })
                .catch((err) => {
                  console.log("Ness Error:", err);
                })
                .finally(() => setLoading(false));
            }}
          />
          <p className="mt-4">{message}</p>
        </div>
      </Container>
    </Page>
  );
};

export default Invitar;
