import React from "react";
import { useSelector } from "react-redux";
import Button from "../components/Button/Button";
import LoadingDots from "../components/Loading/LoadingDots";

const SmallInputNumber = ({
  quoteNumber,
  setQuoteNumber,
  setIsEnterQuote,
  onKeyDown,
  isLoading,
  onOK,
}) => {
  const content = useSelector((state) => state.app.content);

  return (
    <span onBlur={() => quoteNumber < 1 && setIsEnterQuote("")}>
      <span>
        <input
          type="text"
          className="quote-number"
          value={quoteNumber}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, "");
            setQuoteNumber(value);
          }}
          onKeyDown={onKeyDown}
          autoFocus
          placeholder={content.labels.NUMBER}
        />
        {isLoading ? (
          <div>
            <LoadingDots />
          </div>
        ) : (
          <Button label="OK" size="xsmall" click={onOK} />
        )}
      </span>
      <p className="text-press-enter">{content.texts.PRESS_ENTER}</p>
    </span>
  );
};

export default SmallInputNumber;
