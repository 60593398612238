import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../components/Container/Container";
import Page from "../../layout/Page/Page";
import moment from "moment";
import Status from "../../components/Status/Status";
import {
  enterQuotationNumber,
  handleMaterialsFilter,
  handleSurgeriesTabs,
  populateSurgeries,
  setSurgeriesList,
} from "../../store/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import Tooltip from "../../components/Tooltip/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import Filter from "../../components/Filter/Filter";
import FilterColumn from "../../components/FilterColumn/FilterColumn";
import Tabs from "../../components/Tabs/Tabs";
import Loading from "../../components/Loading/Loading";
import "./Surgeries.css";
import Modal from "../../components/Modal/Modal";
import Confirm from "../../components/Modals/Confirm/Confirm";
import { getSurgeries } from "../../services/surgeries";
import SmallInputNumber from "../../common/SmallInputNumber";

const Surgeries = () => {
  const navigate = useNavigate();
  const [chevrons, setChevrons] = useState({
    date: "up",
    patient: "up",
    clinic: "up",
    city: "up",
  });
  const [sortField, setSortField] = useState("date");
  const [isEnterQuote, setIsEnterQuote] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState("");
  const content = useSelector((state) => state.app.content);
  // const isLoading = useSelector((state) => state.app.isLoading);
  const surgeries = useSelector((state) => state.app.surgeries);
  const surgeriesList = useSelector((state) => state.app.surgeriesList);
  const surgeriesTabs = useSelector((state) => state.app.surgeriesTabs);
  const selectedFilters = useSelector((state) => state.app.selectedFilters);
  // const allSurgeries = useSelector((state) => state.app.allSurgeries);
  // const filteredSurgeries = useSelector((state) => state.app.filteredSurgeries);
  const justEdited = useSelector((state) => state.app.justEdited);
  const filterQuery = useSelector((state) => state.app.filterQuery);
  const isShowsMaterials = useSelector((state) => state.app.isShowsMaterials);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);
  const surgeriesPageProps = {
    title: content.labels.SURGERIES,
    subtitle: content.heading.SURGERIES_PAGE_SUBTITLE,
  };
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isSaveToRefresh) {
      if (!location?.state?.surgeryId) {
        const tab = surgeriesTabs.find((st) => st.active);
        setIsLoading(true);
        getSurgeries().then((s) => {
          setIsLoading(false);
          if (s) {
            dispatch(populateSurgeries(s));
            if (tab) {
              dispatch(handleSurgeriesTabs(tab));
            }
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, isSaveToRefresh]);

  const renderStatus = (isComplete, status) => {
    const statusProps = {
      status: isComplete ? "complete" : "pending",
      label: isComplete ? content.labels[status] : content.labels.PENDING,
    };
    return (
      <div className="action-item">
        <Status {...statusProps} />
      </div>
    );
  };

  const openQuotationNumber = (id, quote) => {
    if (quote > 0) {
      setIsOpenConfirm(id);
    } else {
      setQuoteNumber("");
      setIsEnterQuote(id);
    }
  };

  // FIXME: ordenar cirugias por fecha a la mas proxima?
  const sortSurgeries = (value) => {
    setChevrons({
      ...chevrons,
      [value]: chevrons[value] === "down" ? "up" : "down",
    });
    setSortField(value);
    const sortedSurgeries = [...surgeriesList];
    sortedSurgeries.sort((a, b) => {
      if (value === "date") {
        if (chevrons.date === "down") {
          return moment(a.date).diff(moment(b.date));
        } else {
          return moment(b.date).diff(moment(a.date));
        }
      } else {
        if (chevrons[value] === "down") {
          return a[value].localeCompare(b[value]);
        } else {
          return b[value].localeCompare(a[value]);
        }
      }
    });
    dispatch(setSurgeriesList(sortedSurgeries));
  };

  const getHighlightedText = (text) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text?.split(new RegExp(`(${filterQuery})`, "gi"));
    return (
      <>
        {parts?.map((part, index) =>
          part.toLowerCase() === filterQuery.toLowerCase() ? (
            <b className="term-found" key={index}>
              {part}
            </b>
          ) : (
            part
          )
        )}
      </>
    );
  };

  const renderSurgeries = () =>
    surgeriesList?.map((surgery) => {
      const tooltipProps = {
        title: content.labels.MATERIAL,
        subtitle: surgery.patient,
        items: surgery.materials,
      };
      return (
        <div
          className={`surgeries-list-items list-row ${
            justEdited && surgery._id === location?.state?.surgeryId
              ? "just-edited"
              : ""
          }`}
          onClick={() =>
            navigate(`/cirugias/${surgery._id}`, {
              state: { surgery },
            })
          }
          key={surgery._id}
        >
          <span>
            {surgery.date && moment(surgery.date).format("DD/MM/yy hh:mm a")}
          </span>
          <span>{getHighlightedText(surgery.patient)}</span>
          <span>
            {surgery.clinic ? getHighlightedText(surgery.clinic) : "-"}
          </span>
          <span>{surgery.city ? getHighlightedText(surgery.city) : "-"}</span>
          {surgery.materials?.length > 0 ? (
            <span className="material-tooltip">
              {content.labels.VIEW} <FontAwesomeIcon icon="list-ul" />
              <span className="tooltip-wrapper">
                <Tooltip {...tooltipProps} />
              </span>
            </span>
          ) : (
            <span>-</span>
          )}
          <div
            className="action-item"
            onClick={(e) => {
              e.stopPropagation();
              openQuotationNumber(surgery._id, surgery.quoteNumber);
            }}
          >
            {isEnterQuote === surgery._id ? (
              <SmallInputNumber
                quoteNumber={quoteNumber}
                setIsEnterQuote={setIsEnterQuote}
                setQuoteNumber={setQuoteNumber}
                onOK={() => {
                  if (quoteNumber > 0) {
                    dispatch(
                      enterQuotationNumber(surgery._id, quoteNumber)
                    ).then(() => {
                      setIsEnterQuote("");
                    });
                  }
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" &&
                  quoteNumber > 0 &&
                  dispatch(enterQuotationNumber(surgery._id, quoteNumber)).then(
                    () => {
                      setIsEnterQuote("");
                    }
                  )
                }
              />
            ) : surgery.quoteNumber && surgery.quoteNumber > 0 ? (
              <Status status="complete" label={surgery.quoteNumber} />
            ) : (
              <Status status="pending" label={content.labels.PENDING} />
            )}
          </div>
          {renderStatus(!!surgery.despachos?.length, "DELIVERED")}
          {renderStatus(surgery.fileSpend, "RECEIVED")}
        </div>
      );
    });
  return (
    <Page>
      <Container {...surgeriesPageProps} renderComponent={Filter}>
        {isLoading ? (
          <Loading />
        ) : surgeries.length > 0 ? (
          [
            <div key={surgeries.length}>
              <Tabs
                tabs={surgeriesTabs}
                setTab={(tab) => dispatch(handleSurgeriesTabs(tab))}
              />

              <div className="Surgeries">
                <div className="surgeries-list">
                  {surgeriesList.length > 0 ||
                  selectedFilters.length > 0 ||
                  isShowsMaterials ? (
                    <>
                      <div className="surgeries-list-header list-row">
                        <span
                          className="list-header-sort"
                          onClick={() => sortSurgeries("date")}
                        >
                          {content.labels.DATE}
                          <FontAwesomeIcon
                            icon={`chevron-${chevrons.date}`}
                            color={
                              sortField === "date" ? "#1d1f31" : "transparent"
                            }
                          />
                        </span>
                        <span
                          className="list-header-sort"
                          onClick={() => sortSurgeries("patient")}
                        >
                          {content.labels.PATIENT}
                          <FontAwesomeIcon
                            icon={`chevron-${chevrons.patient}`}
                            color={
                              sortField === "patient"
                                ? "#1d1f31"
                                : "transparent"
                            }
                          />
                        </span>
                        <span
                          className="list-header-sort"
                          onClick={() => sortSurgeries("clinic")}
                        >
                          {content.labels.CLINIC}
                          <FontAwesomeIcon
                            icon={`chevron-${chevrons.clinic}`}
                            color={
                              sortField === "clinic" ? "#1d1f31" : "transparent"
                            }
                          />
                        </span>
                        <span
                          className="list-header-sort"
                          onClick={() => sortSurgeries("city")}
                        >
                          {content.labels.CITY}
                          <FontAwesomeIcon
                            icon={`chevron-${chevrons.city}`}
                            color={
                              sortField === "city" ? "#1d1f31" : "transparent"
                            }
                          />
                        </span>
                        <span onClick={() => dispatch(handleMaterialsFilter())}>
                          {content.labels.MATERIAL}
                          <FontAwesomeIcon
                            icon={`eye`}
                            color={isShowsMaterials ? "#1d1f31" : "transparent"}
                          />
                        </span>

                        <FilterColumn name="QUOTE" dbId="quoteNumber" />

                        <FilterColumn name="DELIVERY" dbId="isDispatched" />

                        <FilterColumn name="SPEND" dbId="isSpent" />
                      </div>
                      {renderSurgeries()}
                    </>
                  ) : (
                    <div className="no-surgeries">
                      <FontAwesomeIcon icon={faClipboard} />
                      <p>{content.texts.NO_SURGERIES}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>,
          ]
        ) : (
          <div className="no-surgeries">
            <FontAwesomeIcon icon={faClipboard} />
            <p>{content.texts.NO_SURGERIES}</p>
          </div>
        )}
      </Container>
      <Modal isOpen={isOpenConfirm}>
        <Confirm
          copy={content.texts.CONFIRM_DELETE_QUOTE}
          close={() => setIsOpenConfirm("")}
          confirm={() => {
            dispatch(enterQuotationNumber(isOpenConfirm, 0)).then(() => {
              setIsOpenConfirm("");
            });
          }}
        />
      </Modal>
    </Page>
  );
};

export default Surgeries;
