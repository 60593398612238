import React, { useEffect, useState } from "react";
import moment from "moment";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import IngresosHeader from "../../components/Ingresos/IngresosHeader";
import { getEgresosApi } from "../../services/egresos";
import { useSelector } from "react-redux";
import EgresosList from "../../components/Egresos/EgresosList";

const Egresos = () => {
  const [egresos, setEgresos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);

  let filteredData = egresos.filter((element) =>
    Object.values(element).some((value) => {
      if (new Date(value).getTime()) {
        return moment(element.date).format("DD MMMM YYYY").includes(searchTerm);
      } else if (typeof value === "object") {
        return JSON.stringify(value)
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      } else if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isSaveToRefresh) {
      setIsLoading(true);
      getEgresosApi().then((recentEgresos) => {
        setIsLoading(false);
        setEgresos(recentEgresos);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isSaveToRefresh]);

  return (
    <Page>
      <Container mainTop={false}>
        <IngresosHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          title="Egresos"
        />
        <EgresosList
          filteredData={filteredData}
          isLoading={isLoading}
          setEgresos={setEgresos}
        />
      </Container>
    </Page>
  );
};

export default Egresos;
