import React, { useState } from "react";
import Page from "../layout/Page/Page";
import Container from "../components/Container/Container";
import Input from "../components/Input/Input";
import { ingresarLotesApi } from "../services/lotes";
import Button from "../components/Button/Button";

const IngresarLotes = () => {
  const [lotesString, setLotesString] = useState("");
  const [serverMsg, setServerMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Page>
      <Container mainTop={false}>
        <h1 className="font-semibold text-lg">Ingresar Lotes</h1>
        <Input
          type="textarea"
          className="!h-[400px]"
          value={lotesString}
          handleChange={(e) => setLotesString(e.target.value)}
        />
        {serverMsg && <p className="mt-[-20px] mb-[5px]">{serverMsg}</p>}
        <Button
          disabled={!lotesString}
          isLoading={isLoading}
          label="Ingresar"
          click={() => {
            setIsLoading(true);
            setServerMsg("");
            ingresarLotesApi(lotesString)
              .then((res) => {
                setServerMsg(res);
                setLotesString("");
              })
              .finally(() => setIsLoading(false));
          }}
        />
      </Container>
    </Page>
  );
};

export default IngresarLotes;
