const DashboardRow = ({ remision, cliente, estado }) => {
  return (
    <div>
      <div className="flex justify-between text-xs mb-3">
        <p>{remision}</p>
        <p className="w-[200px] lg:w-[210px]">
          {cliente?.length > 35 ? `${cliente.substring(0, 35)}...` : cliente}
        </p>
        <p className={estado === "En transito" ? "text-carrot-orange" : ""}>
          {estado}
        </p>
      </div>
      <div className="border-[0.5px] border-[#E3E3E3}"></div>
    </div>
  );
};

export default DashboardRow;
