import React, { useState, useRef, useEffect } from "react";
import FullPage from "../../layout/Page/FullPage";
import moment from "moment";
import MaterialCard from "../Surgery/MaterialCard";
import HeaderAction from "../Surgery/HeaderAction";
import Button from "../../components/Button/Button";
import SpendSurgery from "../Surgery/SpendSurgery";
import DropdownSearch from "../../components/DropdownSearch/DropdownSearch";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Input/Input";
import { isNumber } from "../../utils/core";
import Modal from "../../components/Modal/Modal";
import AddLotes from "../../components/Modals/AddLotes/AddLotes";
import { addEgresoApi } from "../../services/egresos";
import { removeSpentLotes } from "../../store/appSlice";
import { useLocation } from "react-router-dom";

const NewEgreso = () => {
  const location = useLocation();
  const [input, setInput] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  const [selectMaterial, setSelectMaterial] = useState("");
  const [quantity, setQuantity] = useState("");
  const [showNewMaterial, setShowNewMaterial] = useState(false);
  const [showAddMaterial, setShowAddMaterial] = useState(false);
  const [egresoFile, setEgresoFile] = useState(null);
  const [swapLote, setSwapLote] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [totalLotes, setTotalLotes] = useState(0);
  const [selectedLotes, setSelectedLotes] = useState([]);
  const [egresoDate, setEgresoDate] = useState(new Date());
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const bodegas = useSelector((state) => state.app.bodegas);
  const materials = useSelector((state) => state.app.materials);
  const lotesData = useSelector((state) => state.app.lotes);
  const [selectedClient, setSelectedClient] = useState({});
  const clients = useSelector((state) => state.app.clients);
  const materialRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const egreso = location.state?.egreso;
    if (egreso) {
      setSelectedClient(egreso.client);
      setSelectedMaterials(egreso.materials);
      setTotalLotes(
        egreso.materials.reduce((acc, currVal) => acc + currVal.quantity, 0)
      );
      setEgresoDate(new Date(egreso.createdAt));
      setEgresoFile(egreso.facturaFile);
    } else if (location.pathname === "/egresos/crear-egreso") {
      setSelectedClient({});
      setSelectedMaterials([]);
      setTotalLotes(0);
      setEgresoDate(new Date());
      setEgresoFile(null);
    }
  }, [location]);

  useEffect(() => {
    if (showNewMaterial) {
      materialRef.current?.focus();
    }
  }, [showNewMaterial]);

  const updateMaterialLotes = () => {
    let lotesToDispatch = selectedMaterials.flatMap((item) => item.lotes);
    lotesToDispatch = lotesData.filter((l) =>
      lotesToDispatch.find((lo) => lo._id === l._id)
    );
    setSelectedLotes(lotesToDispatch);
  };

  const createEgreso = async (file) => {
    const lotes = selectedLotes.map((sl) => sl._id);
    const egreso = {
      client: selectedClient._id,
      materials: selectedMaterials,
      lotes,
    };
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("egreso", JSON.stringify(egreso));
      const newEgreso = await addEgresoApi(formData);
      setEgresoFile(newEgreso.facturaFile);
      dispatch(removeSpentLotes(lotes));
      return newEgreso.facturaFile;
    } catch (err) {
      console.log("Ness error", err);
      setEgresoFile(null);
      throw err;
    }
  };

  return (
    <FullPage>
      <div className="Surgery">
        <div className="surgery-page-header">
          <div className="header-main">
            {!!selectedClient.nombre ? (
              <div className="flex items-center">
                <p className="surgery-page-patient">{selectedClient.nombre}</p>
                {!egresoFile && (
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    style={{ cursor: "pointer", marginLeft: 20 }}
                    onClick={() => setSelectedClient({})}
                  />
                )}
              </div>
            ) : (
              <DropdownSearch
                label="Cliente"
                findField="nombre"
                findField2="numeroId"
                prependField2="tipoId"
                placeholder="Busca y selecciona un cliente"
                suggestions={clients}
                hideTitle
                hideSubinfo
                onSelect={(e, client) => {
                  setSelectedClient(client);
                }}
              />
            )}
            <p className="surgery-page-patient-id mt-3">
              {selectedClient.numeroId &&
                `${selectedClient.tipoId.toUpperCase()}. ${
                  selectedClient.numeroId
                }`}
            </p>
          </div>
          <div className="header-progress-wrapper">
            <div className="header-progress-labels">
              <span></span>
              <span></span>
              <div className="">
                {moment(egresoDate).format("DD MMMM YYYY")}
              </div>
            </div>
          </div>
        </div>

        <div className="surgery-page-section">
          <HeaderAction
            heading="Material"
            action={
              <Button
                click={() => setShowNewMaterial(true)}
                disabled={
                  showNewMaterial || !selectedClient.nombre || egresoFile
                }
                label="Agregar material"
                size="xxsmall"
              />
            }
          />
          <div className="surgery-material-main">
            <div className="material-cards">
              {showNewMaterial && (
                <div className="MaterialCard relative">
                  <div className="flex justify-between">
                    <p className="mb-[-13px]">Material facturado</p>
                    <FontAwesomeIcon
                      icon="times-circle"
                      onClick={() => setShowNewMaterial(false)}
                      className="cursor-pointer"
                    />
                  </div>
                  <Input
                    innerRef={materialRef}
                    type="search2"
                    value={input}
                    size="!w-[95%]"
                    isFocused={inputFocus}
                    placeholder="Buscar material"
                    suggestions={materials.filter(
                      (suggestion) =>
                        (suggestion?.nombre
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                          suggestion?.codigo
                            .toLowerCase()
                            .includes(input.toLowerCase())) &&
                        !selectedMaterials.some(
                          (selectedMaterial) =>
                            selectedMaterial.producto._id === suggestion._id
                        )
                    )}
                    name="nombre"
                    property="codigo"
                    selectSuggestion={(item) => {
                      setSelectMaterial({
                        producto: {
                          _id: item._id,
                          nombre: item.nombre,
                          codigo: item.codigo,
                        },
                        lotes: [],
                      });
                      setInput(item.nombre);
                    }}
                    onCreate={() => {}}
                    onInputFocus={setInputFocus}
                    handleChange={(e) => {
                      setInput(e.target.value);
                    }}
                  />
                  <p className="mb-[-19px]">Cantidad</p>
                  <div className="flex items-end justify-between">
                    <Input
                      type="text"
                      size="!w-[100px]"
                      value={quantity}
                      handleChange={(e) => {
                        setQuantity(e.target.value);
                      }}
                    />
                    <Button
                      label="Guardar"
                      disabled={
                        !selectMaterial || !quantity || !isNumber(quantity)
                      }
                      click={() => {
                        setShowNewMaterial(false);
                        setSelectedMaterials((prev) => [
                          {
                            ...selectMaterial,
                            quantity,
                          },
                          ...prev,
                        ]);
                        setTotalLotes(totalLotes + parseInt(quantity, 10));
                        setInput("");
                        setSelectMaterial("");
                        setQuantity("");
                      }}
                    />
                  </div>
                </div>
              )}

              {selectedMaterials?.length > 0 &&
                selectedMaterials.map((material) => (
                  <MaterialCard
                    key={material.producto._id}
                    onEdit={(productId, newQty) => {
                      const updatedMaterials = selectedMaterials.map((sm) => {
                        if (sm.producto._id === productId) {
                          return {
                            ...sm,
                            quantity: newQty,
                          };
                        }
                        return sm;
                      });
                      setSelectedMaterials(updatedMaterials);
                      setTotalLotes(
                        totalLotes -
                          parseInt(material.quantity, 10) +
                          parseInt(newQty, 10)
                      );
                    }}
                    hasFileSpend={egresoFile}
                    material={material}
                    selectedMaterial={selectedMaterial}
                    onSelect={() => {
                      setSelectedMaterial(material);
                      setShowAddMaterial(true);
                      setSwapLote(null);
                    }}
                    onSwap={(lote) => {
                      setSelectedMaterial(material);
                      setShowAddMaterial(true);
                      setSwapLote(lote);
                    }}
                    onDelete={(lote) => {
                      setSelectedMaterial(material);
                      const updatedSelectedMaterials = selectedMaterials.map(
                        (mat) => {
                          if (mat.producto._id === material.producto._id) {
                            mat.lotes = mat.lotes.filter(
                              (l) => l._id !== lote._id
                            );
                          }
                          return mat;
                        }
                      );
                      setSelectedMaterials(updatedSelectedMaterials);
                    }}
                    removeMaterial={(material) => {
                      const updatedMaterials = selectedMaterials.filter(
                        (sm) => sm.producto._id !== material.producto._id
                      );
                      setTotalLotes(totalLotes - material.quantity);
                      setSelectedMaterials(updatedMaterials);
                    }}
                  />
                ))}
            </div>
            <div className="material-info">
              <div
                className={`${
                  selectedMaterials.map((sm) => sm.lotes).flat().length ===
                    totalLotes && totalLotes !== 0
                    ? "quantity-circle-complete"
                    : "quantity-circle"
                }`}
              >
                <p className="quantity-number">{totalLotes}</p>
                <p>Unidades</p>
              </div>
            </div>
          </div>
        </div>

        <div className="surgery-page-section">
          <HeaderAction heading="Factura" />
          <SpendSurgery
            hasFileSpend={!!egresoFile}
            fileSpend={egresoFile}
            usedLotes={selectedLotes}
            onUpload={(file) => createEgreso(file)}
            isDisabled={
              selectedMaterials.map((sm) => sm.lotes).flat().length !==
                totalLotes || totalLotes === 0
            }
          />
        </div>

        {showAddMaterial && (
          <Modal isOpen={showAddMaterial}>
            <Modal.Container
              title={`${swapLote ? "Cambiar" : "Seleccionar"} lote`}
              close={() => {
                setShowAddMaterial(false);
              }}
            >
              <AddLotes
                selectedMaterial={selectedMaterial}
                lotesData={lotesData.filter(
                  (lote) =>
                    lote.product._id === selectedMaterial.producto?._id &&
                    lote.clientId === selectedClient._id
                )}
                selectedLotes={selectedMaterials.map((sm) => sm.lotes).flat()}
                bodegasData={bodegas.filter(
                  (bodega) => bodega.client._id === selectedClient._id
                )}
                materials={selectedMaterials}
                updateMaterialLotes={updateMaterialLotes}
                setShowAddMaterial={setShowAddMaterial}
                swapLote={swapLote}
              />
            </Modal.Container>
          </Modal>
        )}
      </div>
    </FullPage>
  );
};

export default NewEgreso;
