import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../store/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isPairOpen = useSelector((state) => state.app.isPairOpen);
  const user = useSelector((state) => state.app.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const genericHamburgerLine = `h-0.5 w-6 my-0.5 bg-black rounded-full transition ease transform duration-300`;

  return (
    <div>
      <div className="lg:hidden bg-raisin-black flex justify-between items-end p-5">
        <div>
          <img
            className="w-[150px]"
            src={`../../../assets/logos/${localStorage.getItem("logo")}`}
            alt="Organization logo"
          />
        </div>
        <button
          className="flex flex-col h-6 w-6 justify-end"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={`bg-cadet-blue-crayola ${genericHamburgerLine} ${
              isOpen && "rotate-45 translate-y-1.5"
            }`}
          />
          <div
            className={`bg-cadet-blue-crayola ${genericHamburgerLine} ${
              isOpen && "opacity-0"
            }`}
          />
          <div
            className={`bg-cadet-blue-crayola ${genericHamburgerLine} ${
              isOpen && "-rotate-45 -translate-y-1.5"
            }`}
          />
        </button>
      </div>
      <div
        className={`
          backdrop-blur-xl bg-white/30 w-[88%] h-[100%] 
          fixed right-0 top-[75px] p-10 z-10 
          transition-all duration-500 ease-in-out transform
          ${isOpen ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"}
          ${isOpen ? "pointer-events-auto" : "pointer-events-none"}
        `}
      >
        <div
          className="flex items-center"
          onClick={() => {
            navigate("/");
            setIsOpen(false);
          }}
        >
          <FontAwesomeIcon icon="home" className="w-6" />
          <p className="ml-2">Inicio</p>
        </div>
        <div
          className="flex items-center mt-4"
          onClick={() => {
            navigate("/bodegas");
            setIsOpen(false);
          }}
        >
          <FontAwesomeIcon icon="warehouse" className="w-6 text-sm" />
          <p className="ml-2">Bodegas</p>
        </div>
        {user?.role === "admin" && (
          <div
            className={`flex items-center mt-4 ${
              isPairOpen ? "" : "text-gray-400"
            }`}
            onClick={() => {
              if (isPairOpen) {
                navigate("/escaner");
                setIsOpen(false);
              }
            }}
          >
            <FontAwesomeIcon icon="qrcode" className="w-6" />
            <p className="ml-2">Escáner</p>
          </div>
        )}
        <div
          className="flex items-center mt-4"
          onClick={() => dispatch(logout())}
        >
          <FontAwesomeIcon icon="power-off" className="w-6" />
          <p className="ml-2">Salir</p>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
