import React, { useEffect, useState } from "react";
import moment from "moment";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import IngresosList from "../../components/Ingresos/IngresosList";
import IngresosHeader from "../../components/Ingresos/IngresosHeader";
import { getIngresosApi } from "../../services/ingresos";
import { useSelector } from "react-redux";

const Ingresos = () => {
  const [ingresos, setIngresos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const isSaveToRefresh = useSelector((state) => state.app.isSaveToRefresh);

  let filteredData = ingresos.filter((element) =>
    Object.values(element).some((value) => {
      if (new Date(value).getTime()) {
        return moment(element.date).format("DD MMMM YYYY").includes(searchTerm);
      } else if (typeof value === "object") {
        return JSON.stringify(value)
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      } else if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isSaveToRefresh) {
      setIsLoading(true);
      getIngresosApi().then((recentIngresos) => {
        setIsLoading(false);
        setIngresos(recentIngresos);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isSaveToRefresh]);

  return (
    <Page>
      <Container mainTop={false}>
        <IngresosHeader
          title="Ingresos"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <IngresosList filteredData={filteredData} isLoading={isLoading} />
      </Container>
    </Page>
  );
};

export default Ingresos;
