import React from "react";
import "./SearchSuggestion.css";

// IMPROVED VERSION OF SearchSuggestion
const SearchSuggestion2 = ({
  value,
  name,
  propertyObject,
  property,
  property2,
  suggestions,
  isFocused,
  selectSuggestion,
}) => {
  return (
    <div className="SearchSuggestion">
      <div
        className={`suggestion-container suggestion-container2 ${
          suggestions.length > 0 && value !== "" && isFocused
            ? "show-suggestions"
            : ""
        }`}
        style={{ position: "absolute" }}
      >
        {suggestions.map((item) => (
          <div
            key={item._id}
            className="suggestion suggestion2"
            onMouseDown={() => selectSuggestion(item)}
          >
            <p>{item[name]}</p>
            <p className="secondary-info">
              {propertyObject ? item[propertyObject][property] : item[property]}{" "}
              {property2 &&
                propertyObject &&
                ` - ${item[propertyObject][property2]}`}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchSuggestion2;
