import { useLocation } from "react-router-dom";
import { FaBoxOpen } from "react-icons/fa";
import moment from "moment";

const HeaderLote = ({ inTransit }) => {
  let location = useLocation();

  const lote = location.state?.lote;

  return (
    <div className="grid grid-rows-3">
      <div className="flex items-center">
        <h1
          className={`text-3xl font-semibold ${
            inTransit ? "text-carrot-orange" : ""
          }`}
        >
          {lote.lote}
        </h1>
        {inTransit && (
          <span className="flex justify-center items-center w-20 h-7 ml-6 border border-carrot-orange rounded-lg">
            <span className="text-sm text-carrot-orange font-light">
              En transito
            </span>
          </span>
        )}
      </div>
      <p className="text-xl">
        {!!lote.expiryDate
          ? moment(lote.expiryDate).utcOffset(0).format("DD/MM/YYYY")
          : "-"}
      </p>
      <div>
        <div className="flex">
          <FaBoxOpen className="fill-raisin-black mr-2" />
          <p className="text-xs text-raisin-black">
            <span className="font-light">Referencia actual: </span>
            {lote.product.nombre} - {lote.product.codigo}
          </p>
        </div>
        <div className="w-full h-px bg-cadet-blue-crayola mt-5"></div>
      </div>
    </div>
  );
};

export default HeaderLote;
