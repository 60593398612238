import { useDispatch, useSelector } from "react-redux";
import Page from "../../layout/Page/Page";
import Container from "../../components/Container/Container";
import CreateBodegaModal from "../../components/ReactInventory/CreateBodegaModal";
import { setCreateBodegaModal } from "../../store/appSlice";
import Bodegas from "../../components/Bodegas";

function Warehouses() {
  const clients = useSelector((state) => state.app.clients);
  const isModalOpen = useSelector((state) => state.app.isCreateBodegaModalOpen);
  const dispatch = useDispatch();

  return (
    <Page>
      <Container mainTop={false}>
        <Bodegas />
      </Container>
      {isModalOpen && (
        <CreateBodegaModal
          toggleModal={() => dispatch(setCreateBodegaModal(!isModalOpen))}
          clients={clients}
        />
      )}
    </Page>
  );
}

export default Warehouses;
