import React, { useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../Loading/Loading";
import Modal from "../Modal/Modal";
import IngresosLotesModal from "./IngresosLotesModal";

const IngresosList = ({ isLoading, filteredData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [entries, setEntries] = useState([]);

  return (
    <div className="container mx-auto p-4">
      {isLoading ? (
        <Loading />
      ) : !filteredData.length ? (
        <p className="text-center mt-10">No hay ingresos creados</p>
      ) : (
        <table className="mt-8 min-w-full">
          <thead>
            <tr>
              <th className="p-2 text-center underline font-medium">Fecha</th>
              <th className="p-2 text-center underline font-medium">Hora</th>
              <th className="p-2 text-center underline font-medium">
                No. Fact./Rem.
              </th>
              <th className="p-2 text-center underline font-medium">
                Proveedor
              </th>
              <th className="p-2 text-center underline font-medium">
                Total ingresos
              </th>
              <th className="p-2 text-center underline font-medium">Listado</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((ingreso) => (
              <tr key={ingreso._id}>
                <td className="p-2 text-center font-light text-sm">
                  {moment(ingreso.date).format("DD MMMM YYYY")}
                </td>
                <td className="p-2 text-center font-light text-sm">
                  {moment(ingreso.date).format("hh:mm a")}
                </td>
                <td className="p-2 text-center font-light text-sm">
                  {ingreso.invoiceRemision}
                </td>
                <td className="p-2 text-center font-light text-sm">
                  {ingreso.supplier}
                </td>
                <td className="p-2 text-center font-light text-sm">
                  {ingreso.lotes.length}
                </td>
                <td className="p-2 text-center">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon="clipboard-list"
                    onClick={() => {
                      let entries = ingreso.lotes.map((l) => ({
                        ...l,
                        loteCode: l.product.codigo,
                        description: l.product.nombre,
                        lotes: [
                          {
                            ...l,
                            loteCode: l.product.codigo,
                            description: l.product.nombre,
                          },
                        ],
                      }));
                      setEntries(entries);
                      setOpenModal(true);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal isOpen={openModal}>
        <IngresosLotesModal setIsOpen={setOpenModal} entries={entries} />
      </Modal>
    </div>
  );
};

export default IngresosList;
