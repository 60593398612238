import React from "react";
import { FaSearch } from "react-icons/fa";

const ClientesHeader = ({ setSearchTerm, searchTerm, title, filteredData }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-3/4">
        <div className="grid grid-rows-1 w-full pb-8 justify-items-center items-center">
          <div className="flex">
            <h1 className="text-3xl font-semibold">{title}</h1>
            <div className="w-6 h-6 flex justify-center items-center rounded-full bg-green-300 ml-1">
              <p className="text-xs font-medium">{filteredData.length}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-3/4">
        <div className="flex items-center px-4 py-2 border border-cadet-blue-crayola rounded-lg focus-within:border-transparent focus-within:outline outline-blue-700">
          <FaSearch className="fill-cool-grey mr-2" />
          <input
            className="w-full border-none outline-none placeholder:text-cool-grey"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Busca por nombre, ID, ciudad o telefono"
          />
        </div>
      </div>
    </div>
  );
};

export default ClientesHeader;
