import moment from "moment";
import LoadingDots from "../../components/Loading/LoadingDots";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Button from "../../components/Button/Button";

const MaterialCard = ({
  material,
  hasFileSpend,
  onSelect,
  onDelete,
  onSwap,
  isLoading,
  materialGastado,
  selectedMaterial,
  removeMaterial,
  isSurgery,
  onEdit,
}) => {
  const [qtyEdit, setQtyEdit] = useState("");
  const [editMode, setEditMode] = useState(false);
  const materialLength = material.lotes?.length;

  return (
    <div className="MaterialCard">
      <div className="material-name">
        <p>{material.producto.nombre}</p>
        <div>
          <span
            className={`material-qty ${
              materialLength >= material.quantity ? "material-linked" : ""
            }`}
          >
            {isSurgery ? (
              <>
                {material?.lotes?.length || "0"}/{material.quantity}
              </>
            ) : (
              <>
                <span>
                  {material?.lotes?.length || "0"}/
                  {!editMode ? (
                    material.quantity
                  ) : (
                    <input
                      type="text"
                      value={qtyEdit}
                      onChange={(e) => setQtyEdit(e.target.value)}
                      className="border border-slate-400 rounded-md w-[50px]"
                    />
                  )}
                </span>
                {!editMode ? (
                  <>
                    {!hasFileSpend && (
                      <FontAwesomeIcon
                        icon="pen"
                        color="#bbb"
                        className="cursor-pointer text-xs ml-[2px]"
                        onClick={() => {
                          setEditMode(true);
                          setQtyEdit(material.quantity);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <div>
                    <Button
                      label="Cancelar"
                      size="xxxsmall"
                      variant="cancel"
                      click={() => setEditMode(false)}
                    />
                    <Button
                      size="xxxsmall"
                      label="Guardar"
                      disabled={
                        material.quantity === qtyEdit ||
                        qtyEdit < 1 ||
                        qtyEdit < material?.lotes?.length ||
                        isNaN(qtyEdit)
                      }
                      click={() => {
                        onEdit(material.producto._id, qtyEdit);
                        setEditMode(false);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </span>
        </div>
      </div>
      <div className="material-card-info">
        <p className="material-card-item">Lote</p>
        <div className="material-card-expiry-date">
          <p className="material-card-item">Fecha de vencimiento</p>
        </div>
      </div>
      {materialLength > 0 &&
        material.lotes.map((lote) => (
          <div key={lote._id} className="material-card-selected">
            <p>
              {lote.lote}{" "}
              {lote.deletedAt &&
              isSurgery &&
              !materialGastado?.find(
                (loteGastado) => loteGastado._id === lote._id
              ) ? (
                <span className="border border-gray-400 text-xs text-gray-400 px-0.5 py-0.5 rounded-md ml-1">
                  Gastado
                </span>
              ) : lote.inTransit ? (
                <span className="border border-carrot-orange text-xs text-carrot-orange px-0.5 py-0.5 rounded-md ml-1">
                  En transito
                </span>
              ) : (
                lote.needToBeDispatched &&
                !hasFileSpend && (
                  <span className="border border-red-500 text-xs text-red-500 px-0.5 py-0.5 rounded-md ml-1">
                    Despachar
                  </span>
                )
              )}
            </p>
            <p className="material-card-expiry">
              {moment(lote.expiryDate).utcOffset(0).format("DD/MM/YYYY")}
            </p>
            <div className="material-card-actions mr-6">
              <p className="change-material" onClick={() => onSwap(lote)}>
                {lote.lote && !hasFileSpend && "Cambiar"}
              </p>
              <p className="change-material" onClick={() => onDelete(lote)}>
                {lote.lote && !hasFileSpend && "Borrar"}
              </p>
            </div>
          </div>
        ))}
      {(!material.lotes || materialLength < material.quantity) && (
        <div className="material-card-selected">
          <p>-</p>
          <p>-</p>
          <p></p>
        </div>
      )}
      <div className="flex justify-between">
        {!(materialLength >= material.quantity) && !hasFileSpend ? (
          <span className="link" onClick={onSelect}>
            Seleccionar lotes
          </span>
        ) : (
          <span></span>
        )}
        {removeMaterial && !hasFileSpend && (
          <FontAwesomeIcon
            icon="trash"
            color="#bbb"
            className="cursor-pointer"
            onClick={() => removeMaterial(material)}
          />
        )}
      </div>
      {isLoading &&
        selectedMaterial.producto?._id === material.producto?._id && (
          <LoadingDots style={{ justifyContent: "flex-end", height: 0 }} />
        )}
    </div>
  );
};

export default MaterialCard;
